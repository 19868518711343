<template>
  <!-- https://stackoverflow.com/questions/55068656/safari-mobil-iframe-content-out-of-view-not-rendered -->
  <div
    v-if="!isRecommenderWidget"
    id="tripla-app-wrapper"
    class="app-wrapper"
    :class="{
      'webkit-overflow-scrolling': !isSafari12AndBelow(),
      'overflow-y-auto': isIOS12AndBelow()
    }"
  >
    <template v-if="error && $route.meta.ignoreError">
      <header-navigation :logo="false" />
      <router-view />
    </template>
    <p v-else-if="error">{{ error }}</p>
    <template v-else>
      <div v-if="$route.meta.triplaPay" class="tripla-pay-content">
        <div v-if="(!isInitAPIArrived || !isInitAuthComplete) && !isIframePage" class="text-center">
          <loading visible />
        </div>
        <template v-else>
          <b-alert
            :show="!!globalError"
            fade
            dismissible
            variant="warning"
            class="global-warning"
            @dismissed="clearGlobalError"
          >
            {{ globalError }}
          </b-alert>
          <router-view :key="reloadKey" @changeLocale="changeLocale" />
        </template>
      </div>
      <div v-else class="content" :style="$route?.meta?.contentStyle">
        <b-alert
          v-show="isBrowserObsolete()"
          show
          dismissible
          variant="warning"
          class="text-center"
        >
          {{ $t('base.browserWarning') }}
        </b-alert>
        <form-error
          v-if="globalError"
          class="global-warning"
          :errors="globalError"
          :dismissible="true"
          @dismissed="clearGlobalError"
        />
        <header-navigation
          v-if="isDisplayHeader && isInitAPIArrived && !$route.meta.headless"
          :logo="logo"
          @changeLocale="changeLocale"
        />
        <router-view
          v-if="(isInitAPIArrived && isInitAuthComplete) || isIframePage"
          :key="reloadKey"
          @changeLocale="changeLocale"
        />
        <div v-else-if="!isInitAPIArrived || !isInitAuthComplete" class="text-center">
          <loading visible />
          <!-- <b-spinner v-else class="align-middle mt-71" /> -->
        </div>
        <!-- TODO: show a loader here -->
      </div>
      <bw-footer />
    </template>
    <rooms-preloader v-if="shouldPreloadRooms && sessionToken" />
  </div>
  <div v-else><router-view v-if="isInitAPIArrived && isInitAuthComplete"></router-view></div>
</template>

<script>
import BwFooter from 'components/BwFooter'
import HeaderNavigation from 'components/Navigation'
import RoomsPreloader from 'components/RoomsPreloader'
import Loading from 'components/Common/Loading'
import PATH from 'src/routes/paths'
import { mapGetters, mapActions } from 'vuex'
import {
  browserObsolete,
  isSafari12AndBelow,
  isSafariDesktop,
  iOSVersion,
  isMobile,
  isInIframe,
  sendDataToParentWindow
} from 'utilities/domOperation'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import Membership from 'api/Membership'
import triplabotSessionMixin from 'mixins/triplabotSession'
import { setUserToken, setTokenExtraData, getUserToken } from 'utilities/membership'
import goToSearchPageMixin from 'mixins/goToSearchPageMixin'
import changeLocaleMixin from 'mixins/changeLocaleMixin'
import FormError from 'components/FormError'

export default {
  components: {
    HeaderNavigation,
    BwFooter,
    FormError,
    Loading,
    RoomsPreloader
  },

  mixins: [triplabotSessionMixin, goToSearchPageMixin, changeLocaleMixin],

  metaInfo() {
    const defaultMetaInfo = {}
    if (this.$route.meta.pageTitle) {
      defaultMetaInfo.title = this.$t(`meta.${this.$route.meta.pageTitle}`)
    }
    const favicons = this.meta?.favicon ?? {}
    if (_isEmpty(favicons)) return defaultMetaInfo
    return {
      ...defaultMetaInfo,
      link: [
        { rel: 'icon', sizes: '16x16', href: favicons?.small },
        { rel: 'icon', sizes: '32x32', href: favicons?.medium },
        { rel: 'icon', sizes: '64x64', href: favicons?.large },
        { rel: 'icon', sizes: '128x128', href: favicons?.xlarge },
        { rel: 'icon', sizes: '256x256', href: favicons?.xxlarge },
        { rel: 'icon', sizes: '512x512', href: favicons?.full }
      ]
    }
  },

  data() {
    return {
      isInitAuthComplete: true
    }
  },

  computed: {
    ...mapGetters({
      error: 'error/getInitError',
      initialSetting: 'setting/getInit',
      hotelCode: 'setting/getHotelCode',
      logo: 'setting/getLogo',
      isDisplayHeader: 'setting/isDisplayHeader',
      isInitAPIArrived: 'search/isInitAPIArrived',
      setting: 'setting/getSetting',
      globalError: 'error/getGlobalError',
      isFacilitySignInEnabled: 'setting/isFacilitySignInEnabled',
      meta: 'setting/getMeta',
      isSignedIn: 'membership/isSignedIn',
      userName: 'membership/getUserName',
      sessionToken: 'setting/getSessionToken'
    }),
    allowBrandCustomAuth() {
      return [PATH.booking.result, PATH.facilities].includes(this.$route.name)
    },
    brandCustomAuthToken() {
      const query = this.$route.query
      const tokenName = this.initialSetting?.brand_custom_auth_token_name
      return query?.brand_custom_auth_token || _get(query, tokenName)
    },
    isIframePage() {
      return (
        window.location.href.includes('iframe-token') ||
        window.location.href.includes('iframe-change-locale')
      )
    },
    shouldPreloadRooms() {
      return [PATH.root, PATH.booking, PATH.booking.result].includes(this.$route.name)
    },
    isRecommenderWidget() {
      return this.$route.name === PATH.recommenderWidget
    }
  },

  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        const newRefreshToken = newQuery?.refresh_token
        const oldRefreshToken = oldQuery?.refresh_token
        const currentHotelCode = newQuery?.code

        // If there's a new refresh token on the URL, relogin user
        if (newRefreshToken !== oldRefreshToken && !!newRefreshToken) {
          console.info(
            'exchange triplabot session when refresh token, token exists?',
            !!getUserToken()
          )
          this.reloginUser(newRefreshToken, currentHotelCode)
        }
      },
      immediate: true,
      deep: true
    },
    isInitAPIArrived: {
      handler(newVal, oldVal) {
        if (newVal && !oldVal && this.brandCustomAuthToken && this.allowBrandCustomAuth) {
          this.reloginUserWithBrandCustomAuth(this.brandCustomAuthToken)
        }
      }
    }
  },

  created() {
    window.addEventListener('message', this.handleMessageFromParent)

    if (process.env.BRANCH) {
      console.log(process.env.BRANCH)
      console.log(process.env.LAST_COMMIT)
    }
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (isSafariDesktop() && !isMobile() && isInIframe()) {
        // in iframe
        document.body.classList.add('safari-desktop')
      }
    })

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (isSafariDesktop() && !isMobile() && isInIframe()) {
        document.body.classList.remove('safari-desktop')
      }
    })
  },
  methods: {
    ...mapActions({
      clearGlobalError: 'error/clearGlobalError',
      setLoggedInUser: 'membership/setLoggedInUser'
    }),
    isMobile,
    isSafari12AndBelow,
    isBrowserObsolete() {
      return browserObsolete()
    },
    isIOS12AndBelow() {
      const version = iOSVersion().version
      return !!version && version <= 12
    },

    handleMessageFromParent(event) {
      const { parentUrl } = this.setting
      if (process.env.TARGET_ENV !== 'production') {
        // console.log('BW receive:', event)
      }
      if (
        parentUrl !== '*' &&
        (!parentUrl || event.origin !== new URL(decodeURIComponent(parentUrl)).origin)
      )
        return

      if (event.data.action === 'logout') {
        if (this.isInitAPIArrived) {
          this.$store.dispatch('membership/logout')
        } else {
          this.$store.dispatch('setting/setShouldLogoutAfterInitAPIArrived', true)
        }
      } else if (event.data?.action === 'refresh-login-from-chatbot') {
        // when user has signed in/out on CB, it will emit refresh-login-from-chatbot
        // we need to check token in localStorage and do sign in or out
        const token = getUserToken()
        if (token) {
          setUserToken(token)
          Membership.getUserInfo()
            .then(this.setLoggedInUser)
            .then(() => {
              const routeNames = [
                PATH.membership.signIn,
                PATH.membership.signUp,
                PATH.membership.forgotPassword
              ]
              if (routeNames.includes(this.$route.name)) {
                this.goToSearchPage()
              }
            })
        } else {
          // we don't wanna send sign out when user has signed out already so
          // just make sure there's no user token there
          this.$store.dispatch('membership/logoutLocally').then(() => {
            this.$router.push({
              name: PATH.booking.result,
              query: {
                code: this.setting.code
              }
            })
          })
        }
      }
    },

    reloginUser(refresh_token, hotel_code) {
      this.$store.dispatch('membership/logout').then(() => {
        this.loginUser(refresh_token, hotel_code)
      })
    },

    loginUser(refresh_token, hotel_code) {
      Membership.getNewToken(
        {
          token: refresh_token
        },
        hotel_code
      )
        .then((response) => {
          this.loginAndRedirect(response)
          this.$store.dispatch('error/setGlobalError', null)
        })
        .catch((err) => {
          this.$store.dispatch('error/setGlobalError', err.title)
        })
    },

    loginAndRedirect(response) {
      setUserToken(response?.session)
      this.setLoggedInUser(response)
      sendDataToParentWindow({
        action: 'login',
        name: this.userName
      })
      this.sendTriplabotSession()
    },

    async reloginUserWithBrandCustomAuth(token) {
      this.isInitAuthComplete = false
      if (this.isSignedIn) {
        await this.$store
          .dispatch('membership/logout', {
            skipChatbotRefresh: true
          })
          .catch(() => {})
      }
      this.$store.dispatch('error/setGlobalError', null)
      const res = await Membership.brandCustomAuth(token).catch(() => {
        this.$store.dispatch('error/setGlobalError', this.$t('customAuth.failDesc'))
      })
      this.isInitAuthComplete = true
      if (res?.session) {
        setTokenExtraData({ isLoginByBrandAuth: true, brandAuthToken: token })
        console.info(
          'exchange triplabot session from reloginUserWithBrandCustomAuth, token exists',
          !!getUserToken()
        )
        this.loginAndRedirect(res)
        this.$store.dispatch('membership/setIsLoginByBrandAuth', true)
        this.$store.dispatch('membership/setBrandAuthToken', token)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/vars.scss';
@import '~stylesheets/mixins.scss';

.app-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.overflow-y-auto {
  overflow-y: auto;
}

.content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background: $background-gray;
}

.webkit-overflow-scrolling {
  -webkit-overflow-scrolling: touch;
}

.global-warning {
  width: 60%;
  position: fixed;
  top: 8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 104;
  animation: fixedfadeDown 0.5s;
}

@include media-breakpoint-down(md) {
  .global-warning {
    width: 100%;
  }
}

.mt-71 {
  margin-top: 17.75rem;
}

.tripla-pay-content {
  background-color: $tripla-pay-bg;
  padding: 11px 0;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes fixedfadeDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
