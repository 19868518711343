export default {
  "additionalQuestion": {
    "adultFemale": "หญิง",
    "adultMale": "ชาย",
    "adultOther": "อื่น ๆ",
    "answerQuestions": "คุณช่วยตอบคำถามเพิ่มเติมได้ไหม",
    "checkinTime": "เวลาเช็คอิน",
    "checkoutTime": "เวลาเช็คเอาท์",
    "moreDetailed": "เราจำเป็นต้องทราบข้อมูลเพิ่มเติมอีกเล็กน้อยเกี่ยวกับการเข้าพักของคุณ",
    "notificationFromFacility": "การแจ้งเตือนจากสถานที่",
    "selectTime": "เลือกเวลา",
    "view": "ดู",
    "customizeYourStayExp": "ปรับแต่งประสบการณ์การเข้าพักของคุณ",
    "customizeYourStayExpDesc": "คุณช่วยตอบคำถามเพิ่มเติมอีกสองสามข้อได้ไหม"
  },
  "turnstile": {
    "unsupportedBrowser": "เบราว์เซอร์ที่คุณใช้งานไม่รองรับ กรุณาใช้ Chrome, Firefox, Safari หรือ Microsoft Edge"
  },
  "base": {
    "account": "บัญชี",
    "all": "ทั้งหมด",
    "apply": "นำมาใช้",
    "attachFile": "แนบไฟล์",
    "backTo": "กลับไปที่ {0}",
    "booking": "การจอง",
    "browserWarning": "เบราว์เซอร์ของคุณไม่ได้รับการสนับสนุนอย่างเป็นทางการ หากคุณพบปัญหาในขั้นตอนการจอง โปรดอัปเกรดเบราว์เซอร์ของคุณเป็นเวอร์ชันล่าสุด",
    "cancel": "ยกเลิก",
    "close": "ปิด",
    "confirm": "ยืนยันการจองของคุณ",
    "continue": "ดำเนินการต่อ",
    "collapse": "ทรุด",
    "delete": "ลบ",
    "edit": "แก้ไข",
    "error": "บางอย่างผิดพลาด",
    "error02": "เกิดข้อผิดพลาดระหว่างการประมวลผล. กรุณาลองใหม่อีกครั้งในภายหลัง.",
    "errorPaymentMethod": "ไม่สามารถดำเนินการจองห้องพักหลายห้องโดยใช้วิธีการชำระเงินที่แตกต่างกันได้ โปรดเลือกห้องพักของคุณด้วยวิธีการชำระเงินเดียวกัน หรือจองห้องพักโดยแยกตามลำดับ",
    "oldErrorCode": {
      "1403": "บัญชีนี้ได้รับการลงทะเบียนโดยหน่วยงานอำนวยความสะดวก และจำเป็นต้องรีเซ็ตรหัสผ่าน โปรดกลับไปที่หน้าลงชื่อเข้าใช้แล้วกด \"ลืมรหัสผ่าน\""
    },
    "errorCode": {
      "1501": "คุณใช้คูปองนี้ถึงขีดจำกัดแล้ว และไม่สามารถใช้ได้อีก",
      "1903": "สามารถจองได้ {reservable_term_months} ​​เดือนนับจากวันนี้\nกรุณาระบุระยะเวลาการจอง",
      "2304": "การตั้งค่าแผนไม่สอดคล้องกับสถานะบัญชีของคุณโดยไม่ทราบสาเหตุ กรุณารีเฟรชหน้านี้และจองใหม่อีกครั้ง",
      "2401": "คุณมีคะแนนเพียง {currentPoint} เท่านั้น ไม่สามารถใช้ {spendPoint} แต้มได้",
      "3001": "เกิดข้อผิดพลาดการหมดเวลา การชำระเงินยังไม่ได้รับการประมวลผล",
      "3002": "เกิดข้อผิดพลาดและการชำระเงินล้มเหลว การจองของคุณยังไม่เสร็จสมบูรณ์ กรุณาลองใหม่อีกครั้ง",
      "3003": "การ์ดใบนี้ไม่สามารถใช้ได้",
      "3004": "กรุณายืนยันหมายเลขบัตร",
      "3005": "โปรดตรวจสอบให้แน่ใจว่าวันหมดอายุของคุณถูกต้อง",
      "3006": "การรับรองความถูกต้อง 3D Secure ล้มเหลว",
      "3007": "ไม่รับบัตรเครดิตหรือหมายเลขบัตรไม่ถูกต้อง",
      "3008": "การรับรองความถูกต้อง 3D Secure ถูกยกเลิก",
      "3009": "การรับรองความถูกต้อง 3D Secure ถูกระงับเนื่องจากมีคำขอหลายรายการ",
      "3011": "เกิดข้อผิดพลาดการหมดเวลา การชำระเงินยังไม่ได้รับการประมวลผล",
      "3013": "ไม่สามารถอัปเดตการจองนี้ได้ในขณะนี้เนื่องจากมีการอัปเดตหรือดำเนินการชำระเงินอยู่ โปรดลองอีกครั้งในอีกประมาณ 5 นาที",
      "3014": "เนื่องจากเนื้อหาการจองเดิมของคุณได้รับการอัปเดตแล้ว โปรดยกเลิกและยืนยันเนื้อหาใหม่อีกครั้งก่อนที่จะอัปเดต",
      "3016": "ไม่สามารถอัปเดตการจองนี้ได้ในขณะนี้เนื่องจากมีการอัปเดตหรือดำเนินการชำระเงินอยู่ โปรดลองอีกครั้งในอีกประมาณ 5 นาที",
      "3202": "คะแนนคงเหลือของคุณไม่สามารถใช้งานได้",
      "3203": "เกิดข้อผิดพลาดระหว่างการประมวลผล. กรุณาลองใหม่อีกครั้งในภายหลัง.",
      "3204": "กระบวนการชำระเงินล้มเหลว โปรดใช้บัตรอื่นหรือลองอีกครั้งในภายหลัง",
      "3205": "การ์ดใบนี้ไม่สามารถใช้ได้",
      "3250": "การรับรองความถูกต้อง 3D Secure ล้มเหลว",
      "3251": "เกิดข้อผิดพลาดระหว่างการประมวลผล. กรุณาลองใหม่อีกครั้งในภายหลัง.",
      "3252": "การ์ดที่ป้อนเข้ากันไม่ได้กับ 3D Secure",
      "3502": "คะแนนคงเหลือของคุณไม่สามารถใช้งานได้",
      "3504": "ไม่สามารถชี้กลับได้ โปรดยืนยันประวัติการชี้ของคุณ",
      "3750": "วันที่เหล่านี้มีไว้สำหรับกิจกรรมลอตเตอรีเท่านั้น โปรดตรวจสอบเว็บไซต์ของโรงแรมสำหรับรายละเอียดเพิ่มเติม",
      "3751": "กิจกรรมลอตเตอรีนี้ไม่สามารถใช้ได้ในขณะนี้ โปรดตรวจสอบเว็บไซต์ของโรงแรมเพื่อดูรายละเอียดเพิ่มเติม",
      "3752": "กิจกรรมลอตเตอรีนี้ไม่สามารถใช้ได้ในขณะนี้ โปรดตรวจสอบเว็บไซต์ของโรงแรมเพื่อดูรายละเอียดเพิ่มเติม",
      "3753": "กิจกรรมลอตเตอรีนี้ไม่สามารถใช้ได้ในขณะนี้ โปรดตรวจสอบเว็บไซต์ของโรงแรมเพื่อดูรายละเอียดเพิ่มเติม",
      "3754": "กิจกรรมลอตเตอรีนี้ไม่สามารถใช้ได้ในขณะนี้ โปรดตรวจสอบเว็บไซต์ของโรงแรมเพื่อดูรายละเอียดเพิ่มเติม",
      "3756": "คุณได้เข้าลอตเตอรี่นี้แล้ว กรุณาคลิกที่ \"การจอง\" และไปที่ส่วน \"ลอตเตอรี\" ของหน้าโปรไฟล์สมาชิกของคุณเพื่อดูรายการของคุณ",
      "3757": "กิจกรรมลอตเตอรีนี้ไม่สามารถใช้ได้ในขณะนี้ โปรดตรวจสอบเว็บไซต์ของโรงแรมเพื่อดูรายละเอียดเพิ่มเติม",
      "3760": "กิจกรรมลอตเตอรีนี้ไม่สามารถใช้ได้ในขณะนี้ โปรดตรวจสอบเว็บไซต์ของโรงแรมเพื่อดูรายละเอียดเพิ่มเติม",
      "3780": "ลิงค์ของคุณหมดอายุหรือไม่ถูกต้อง โปรดเยี่ยมชมเว็บไซต์ของโรงแรมอีกครั้งและเข้าสู่กิจกรรมลอตเตอรี่นี้อีกครั้ง",
      "3781": "ลิงค์ของคุณหมดอายุหรือไม่ถูกต้อง โปรดเยี่ยมชมเว็บไซต์โรงแรมอีกครั้งและเข้าสู่กิจกรรมลอตเตอรี่นี้อีกครั้ง",
      "3782": "ลิงค์ของคุณหมดอายุหรือไม่ถูกต้อง โปรดเยี่ยมชมเว็บไซต์ของโรงแรมอีกครั้งและเข้าสู่กิจกรรมลอตเตอรี่นี้อีกครั้ง",
      "3783": "ลิงค์ของคุณหมดอายุหรือไม่ถูกต้อง โปรดเยี่ยมชมเว็บไซต์โรงแรมอีกครั้งและเข้าสู่กิจกรรมลอตเตอรี่นี้อีกครั้ง"
    },
    "sessionExpired": "เซสชั่นหมดอายุแล้ว กรุณารีเฟรชหน้าเพื่อดำเนินการต่อ",
    "notAvailableYet": "",
    "fileLimit80MB": "ขนาดไฟล์อัพโหลดสูงสุด 80MB ต่อไฟล์ภาพ",
    "upTo30FilesError": "ไม่สามารถแนบไฟล์ได้มากกว่า 30 ไฟล์",
    "upTo30Files": "มากถึง 30 ไฟล์",
    "textLengthLimit": "ข้อความคำตอบต้องมีอักขระไม่เกิน 255 ตัว",
    "triplaCoLtd": "บริษัท ทริปปลา จำกัด",
    "triplaAi": "tripla.ai",
    "GDPR": "นโยบาย GDPR",
    "here": "ที่นี่",
    "imageError": "โหลดรูปภาพไม่สำเร็จ",
    "join": "ลงชื่อ",
    "languages": {
      "en": "ภาษาอังกฤษ",
      "ja": "日本語",
      "ko": "เกาหลี",
      "zh_Hans": "简体中文",
      "zh_Hant": "繁體中文"
    },
    "loading": "กำลังค้นหา...",
    "search": "ค้นหา",
    "login": "เข้าสู่ระบบ",
    "logout": "ออกจากระบบ",
    "logoutMessage": "คุณได้ออกจากระบบแล้ว",
    "signInStatusChangeMessage": "สถานะการลงชื่อเข้าใช้มีการเปลี่ยนแปลง และคุณจะถูกเปลี่ยนเส้นทางไปยังหน้าการค้นหา",
    "memberSignIn": "เข้าสู่ระบบสมาชิก",
    "memberSignUp": "ลงชื่อ",
    "next": "ต่อไป",
    "notSelected": "ไม่ได้เลือก",
    "noData": "ไม่มีข้อมูล",
    "optional": "ไม่จำเป็น",
    "pay": "จ่าย",
    "previous": "ก่อนหน้า",
    "previousPage": "หน้าก่อนหน้า",
    "privacyPolicy": "นโยบายความเป็นส่วนตัว",
    "readMore": "อ่านเพิ่มเติม",
    "readLess": "อ่านน้อยลง",
    "recommended": "ที่แนะนำ",
    "remove": "ลบ",
    "required": "ที่จำเป็น",
    "requireMessage": "ช่องที่ต้องกรอกต้องไม่เว้นว่าง",
    "reservationStatuses": {
      "cancelled": "ยกเลิก",
      "done": "ที่สงวนไว้"
    },
    "save": "บันทึก",
    "signInUp": "เข้าสู่ระบบ / ขึ้น",
    "submit": "ส่ง",
    "termError": "กรุณายอมรับข้อกำหนดและเงื่อนไข",
    "taxMessage": "ปัจจุบันอัตราภาษีการบริโภคอยู่ที่ 8% แต่คาดว่าจะเพิ่มขึ้นเป็น 10% ในเดือนตุลาคม 2019 สำหรับการจองที่พักหลังเดือนตุลาคม โปรดดูยอดรวมโดยตรงบนหน้าจอยืนยันการจอง ขอขอบคุณสำหรับความร่วมมือและความเข้าใจของคุณ",
    "termsOfUse": "ข้อกำหนดการใช้งาน",
    "topPage": "หน้าบนสุด",
    "update": "อัปเดต",
    "success": "ความสำเร็จ",
    "unsubscribe": "ยกเลิกการสมัคร",
    "unsubscribeConfirmation": {
      "error": "ข้อผิดพลาด. กรุณาลองใหม่อีกครั้งในภายหลัง.",
      "title": "เราเสียใจที่เห็นคุณไป ",
      "description": "หากคุณยังคงต้องการยกเลิกการสมัครรับอีเมลส่งเสริมการขายของเรา โปรดคลิกปุ่ม"
    },
    "unsubscribeSuccessDescribe": "คุณได้ยกเลิกการสมัครแล้ว เราจะไม่ส่งอีเมลส่งเสริมการขายของเราอีกต่อไป",
    "warning": {
      "kidsChanged": "สิ่งอำนวยความสะดวกนี้ไม่รองรับเงื่อนไขการค้นหาที่ระบุ ดังนั้นเงื่อนไขการค้นหาสำหรับเด็กจึงมีการเปลี่ยนแปลง"
    },
    "formalName": "{name}",
    "maintenanceTitle": "เว็บไซต์กำลังอยู่ในระหว่างการบำรุงรักษา",
    "maintenanceDesc": "ขออภัยในความไม่สะดวกที่เกิดขึ้น เราใกล้จะเสร็จแล้ว",
    "noResultAvailable": "ไม่มีผลการค้นหาที่ตรงกัน",
    "taxAndServiceFeeIncluded": "รวมภาษีและค่าบริการแล้ว"
  },
  "roomLottery": {
    "updateLottery": "อัพเดทรายการลอตเตอรี่ของคุณ",
    "restoreLottery": "คืนค่ารายการลอตเตอรีของคุณ",
    "updateDisabledDesc": "หมดเขตเข้าสลากนี้แล้วกรุณารอประกาศผล",
    "viewUpdateDetails": "ดู/อัปเดตรายละเอียด",
    "restoreUpdateEntry": "คืนค่า / อัปเดตรายการ",
    "updateProfileDesc": "หลังจากถูกลอตเตอรี่และทำการจองแล้ว ข้อมูลข้างต้นที่ให้ไว้จะถูกนำมาใช้และไม่สามารถเปลี่ยนแปลงได้",
    "cancellationEmailDesc": "เราได้ส่งอีเมลแจ้งการยกเลิกไปที่ {0}",
    "cancelModalTitle": "ยกเลิกการเข้าลอตเตอรีของคุณ",
    "cancelModalDesc": "คุณต้องการยกเลิกรายการลอตเตอรีของคุณหรือไม่? การดำเนินการนี้ไม่สามารถยกเลิกได้",
    "cancelEntry": "ยกเลิกการเข้า",
    "reservationDeadlinePassed": "หมดเขตการจองแล้ว",
    "reservationDeadlinePassedDesc": "ขออภัย กำหนดเวลาการจองได้ผ่านไปแล้ว สำหรับรายละเอียดเพิ่มเติม กรุณาเยี่ยมชมเว็บไซต์ของโรงแรมหรือติดต่อเราโดยตรง",
    "accountNotMatched": "บัญชีนี้ไม่ตรงกับบัญชีที่ใช้สมัครหวย กรุณาเข้าสู่ระบบด้วยบัญชีที่ถูกต้อง",
    "resultDateNotice": "ผลลัพธ์จะประกาศในวันที่ {date} ({day}) {time} ~",
    "resultDateNotice02": "เฉพาะผู้ชนะที่เลือกเท่านั้นที่จะได้รับอีเมลอีกฉบับเพื่อดำเนินการตามขั้นตอนการจองจริง",
    "resultDateNotice03": "คุณสามารถตรวจสอบสถานะได้ตลอดเวลาภายใต้ \"การจอง\" ในเมนูสมาชิก",
    "lotteryEntryNumber": "เลขที่เข้าสลาก",
    "lotteryEntryConfirmed": "รายการลอตเตอรีของคุณได้รับการยืนยันแล้ว",
    "lotteryEntryCancelled": "รายการลอตเตอรีของคุณถูกยกเลิกแล้ว",
    "lotteryResultNotSelected": "คุณไม่ได้รับเลือกให้เข้าร่วมกิจกรรมลอตเตอรีนี้",
    "notSelectedNotice": "ขอบคุณที่เข้าร่วม เราหวังว่าจะได้พบคุณในครั้งต่อไป",
    "lotteryResultSelectedNotified": "🎉 คุณได้รับเลือกให้เป็นผู้ชนะ!!",
    "lotteryWinnerNotice01": "※ การจองยังไม่ได้รับการยืนยัน กรุณากรอกขั้นตอนการจองให้เสร็จสิ้น",
    "lotteryWinnerNotice02": "กำหนดเวลาการจองจนถึง {date} ({day}) {time}",
    "manageYourLottery": "จัดการลอตเตอรี่",
    "reservationIncomplete": {
      "errorMessage": "มีกระบวนการอื่นเกิดขึ้น โปรดตกลงที่จะยกเลิกหากคุณต้องการดำเนินการต่อ",
      "checkboxMessage": "ฉันตกลงที่จะยกเลิกกระบวนการนี้",
      "modalMessage": "ขั้นตอนการจองเสร็จสมบูรณ์แล้วและจะแสดงผลผลลัพธ์"
    }
  },
  "bookingDetail": {
    "overlappedReservation": "คุณได้กรอก {0} ซึ่งทับซ้อนกับวันที่เช็คอินและเช็คเอาต์ที่คุณจองไว้ในขณะนี้ โปรดยืนยันอีกครั้งก่อนทำการจอง\n*การจองที่ทับซ้อนกันอาจมาจากสิ่งอำนวยความสะดวกอื่นภายใต้แบรนด์โรงแรมนี้",
    "overlappedReservationLink": "การจอง",
    "overlappedReservationGuest": "คุณได้เสร็จสิ้นการจองซึ่งทับซ้อนกับวันที่เช็คอินและเช็คเอาท์ที่คุณจองไว้ในขณะนี้ โปรดยืนยันอีกครั้งก่อนทำการจอง\n*การจองที่ทับซ้อนกันอาจมาจากสิ่งอำนวยความสะดวกอื่นภายใต้แบรนด์โรงแรมนี้\n*หากคุณเคยยกเลิกการจองด้วยอุปกรณ์อื่นมาก่อน การตรวจจับนี้อาจผิดพลาด โปรดยืนยันทางอีเมลที่คุณได้รับ",
    "overlappedReservationGuest2": "*หากคุณยกเลิกการจองด้วยอุปกรณ์อื่นหรือล้างคุกกี้ การตรวจจับนี้อาจผิดพลาด โปรดยืนยันทางอีเมลที่คุณได้รับ"
  },
  "bookingResult": {
    "paymentPromotionBanner": {
      "description": "",
      "message": "เพลิดเพลินกับส่วนลดพิเศษ {0} เมื่อคุณชำระเงินด้วยบัตรเครดิตของคุณ",
      "limitedTimeOnly": "ข้อเสนอนี้ใช้ได้ในช่วงเวลาจำกัดเท่านั้น!",
      "title2": "ชำระเงินด้วยบัตรเครดิตของคุณและประหยัด!",
      "title3": ""
    },
    "allUser": "ทั้งหมด",
    "availableBetween": "มีจำหน่ายระหว่าง",
    "bath": "อาบน้ำ",
    "shower": "อาบน้ำ",
    "bathtub": "อ่างอาบน้ำ",
    "bedCount": "{n} เตียง | {n} เตียง | {n} เตียง",
    "book": "หนังสือ",
    "bookAirline": "เที่ยวบิน + ห้อง",
    "flightRooms": "เที่ยวบิน + ห้อง",
    "bookAirlineWarning": "ตัวเลือก {0} ใช้ได้เฉพาะวันที่เช็คอิน {1} เท่านั้น โปรดปรับเปลี่ยนวันที่เช็คอินของคุณเพื่อเข้าถึงตัวเลือกนี้",
    "bookAirlineWarningStay": "ตัวเลือก {0} มีให้สำหรับระยะเวลาการเข้าพักสูงสุด {1} วันเท่านั้น โปรดปรับระยะเวลาการเข้าพักของคุณเพื่อเข้าถึงตัวเลือกนี้",
    "cancellationFreeUntil": "ยกเลิกฟรีจนถึง {0} (เวลาท้องถิ่น)",
    "cancellationPolicy": "นโยบายการยกเลิก",
    "discountAmount": "จำนวนส่วนลด",
    "excludeDuring": "ไม่รวมในระหว่าง",
    "forNights": "สำหรับ {n} คืน | สำหรับ {n} คืน | เป็นเวลา {n} คืน",
    "freeWifi": "ฟรี WiFi ในห้องพัก",
    "followingSearchConditions": "วันนี้มีห้องว่าง.",
    "guests": "แขก",
    "includes": "รวมถึง",
    "planInformation": "ข้อมูลแผน",
    "lowestPriceInfo": "ราคาต่ำสุดอาจไม่แสดงในปฏิทิน ขึ้นอยู่กับสถานะสินค้าคงคลังและเงื่อนไขส่วนลด กรุณาตรวจสอบราคาจริงจากปฏิทิน",
    "maxAdults": "จำนวนผู้ใหญ่สูงสุดคือ {n}",
    "meals": "มื้ออาหาร",
    "memberExclusive": "เฉพาะสมาชิกเท่านั้น",
    "membersOnly": "สมาชิกเท่านั้น",
    "nonSmoking": "ไม่สูบบุหรี่",
    "noRoomFound": "ขออภัย การค้นหาของคุณไม่มีห้องว่าง",
    "tryChanging": "ลองเปลี่ยน {0} หรือตำแหน่งของคุณ",
    "tryItem": "วันที่",
    "noRoomSelected": "คุณยังไม่ได้เลือกห้องใดๆ",
    "noRoomsAvailable": "ขออภัย การค้นหาของคุณไม่มีห้องว่าง",
    "noRoomsAvailableDesc": "ลองเปลี่ยนวันที่หรือสถานที่ของคุณ",
    "notAvailable": "ไม่สามารถใช้ได้",
    "priceFor": "ราคาสำหรับ",
    "priceForAdult": "{n} ผู้ใหญ่ | {n} ผู้ใหญ่ | {n} ผู้ใหญ่",
    "priceForChild": "{n} ลูก | {n} ลูก | {n} เด็ก ๆ",
    "priceForRoom": "ห้อง",
    "paymentCreditCard": "ชำระเงินออนไลน์ด้วยบัตรเครดิต",
    "paymentMethods": "การชำระเงิน",
    "paymentMethod": {
      "on_site": "ชำระเงินนอกสถานที่",
      "credit_card": "ชำระเงินออนไลน์ด้วยบัตรเครดิต",
      "on_site_credit_card_required": "ชำระเงินนอกสถานที่ (ต้องลงทะเบียนบัตรเครดิต)",
      "deferred_payment": "การชำระเงินเลื่อนออกไป",
      "credit_card_full": "ชำระเงินเต็มจำนวนด้วยบัตรเครดิต",
      "credit_card_deposit": "ฝากเงินด้วยบัตรเครดิต",
      "bank_transfer": "โอนเงินผ่านธนาคาร",
      "credit_card_krw_domestic": "ชำระเงินออนไลน์ (บัตรเครดิตในประเทศเกาหลี)",
      "krw_domestic_description": "ขณะนี้บัตร Lotte ไม่สามารถใช้งานได้ แต่จะสามารถใช้ได้เร็วๆ นี้",
      "credit_card_krw_international": "ชำระเงินออนไลน์ (บัตรเครดิตระหว่างประเทศ)"
    },
    "paymentNewCreditCards": "ชำระเงินออนไลน์ผ่านบัตรเครดิตใหม่",
    "paymentRegisteredCreditCards": "ชำระเงินออนไลน์ผ่านบัตรเครดิตที่ลงทะเบียน",
    "planDetails": "รายละเอียดแผน",
    "plansLimitForUpdating": "คุณไม่สามารถเลือกแผนได้ยกเว้นแผนในการจองเดิม",
    "price": "ราคา",
    "promotionMayChanged": "โปรโมชั่นอาจมีการเปลี่ยนแปลงหรือปิดโดยไม่มีการเตือนล่วงหน้า",
    "registerCard": "ลงทะเบียนบัตรนี้",
    "regularRate": "อัตราปกติ",
    "reservationStartDate": "วันที่เริ่มจอง",
    "showRoomDetails": "แสดงรายละเอียดห้องพัก",
    "roomDetails": "รายละเอียดห้องพัก",
    "roomFeatures": "ข้อมูลห้องพัก",
    "roomLeft": "ไม่มีห้องเหลือ | เหลือเพียง {n} ห้องเท่านั้น! - เหลือเพียง {n} ห้องเท่านั้น!",
    "roomAvailable": "ไม่มีห้องเหลือ | {n} ห้องเหลือ | เหลืออีก {n} ห้อง",
    "roomEnough": "มีอยู่",
    "roomRate": "ประเมิน",
    "rooms": "ห้องพัก",
    "roomSize": "ขนาดห้อง",
    "roomSizeUnit": {
      "tatami_mats": "เสื่อทาทามิ"
    },
    "select": "เลือก",
    "selectNewDate": "เลือกวันที่ใหม่",
    "serviceFeeDesc": "ราคาเหล่านี้รวมค่าบริการที่ดำเนินการโดยเว็บไซต์จอง",
    "sleeps": "แขก",
    "smoking": "สูบบุหรี่",
    "stopReservationUpdate": "หยุดกระบวนการอัพเดตการจอง",
    "stopLotteryEntryRestore": "หยุดกระบวนการเรียกคืนลอตเตอรี",
    "stopLotteryEntryUpdate": "หยุดกระบวนการอัพเดทลอตเตอรี่",
    "targetUser": "ผู้ใช้เป้าหมาย",
    "taxExcluded": "ไม่รวมภาษี",
    "taxIncluded": "รวมภาษี",
    "toilet": "ห้องน้ำ",
    "totalPrice": "",
    "totalPriceForNights": "ราคารวมสำหรับ {n} คืน | ราคารวมสำหรับ {n} คืน | ราคารวมสำหรับ {n} คืน",
    "viewAll": "ดูทั้งหมด",
    "viewClose": "ดูน้อยลง",
    "viewGallery": "ดูภาพทั้งหมด",
    "viewLess": "ดูน้อยลง",
    "viewMore": "ดูแผน {n} ทั้งหมด | ดูแผน {n} ทั้งหมด",
    "viewAllRooms": "ดูทั้งหมด {n} ห้อง | ดูห้องทั้งหมด {n} ห้อง",
    "wifi": "อินเตอร์เน็ตไร้สาย",
    "wifiOption1": "ไม่สามารถใช้ได้",
    "wifiOption2": "ฟรี",
    "wifiOption3": "ค่าใช้จ่าย",
    "searchResults": "ผลการค้นหา:",
    "nPlans": "{n} แผน | {n} แผน",
    "nRoomTypes": "{n} ประเภทห้อง | {n} ประเภทห้องพัก"
  },
  "customAuth": {
    "failTitle": "เข้าสู่ระบบล้มเหลว",
    "failDesc": "การเข้าสู่ระบบล้มเหลวเนื่องจากไม่ได้ลงทะเบียนข้อมูลสมาชิก"
  },
  "cancellationPolicy": {
    "policyHeader": "ค่าธรรมเนียมการยกเลิกต่อไปนี้จะนำไปใช้กับการจองของคุณ:",
    "policyLine": "{0} วันก่อนเช็คอิน: {1}% ของอัตรา",
    "policyLineWithTime": "{0} วันก่อนเช็คอิน หลัง {1}: {2}% ของอัตรา",
    "freePolicyLine": "{0} วันก่อนเช็คอิน: ฟรี",
    "noShowLine": "ไม่แสดงตัว: {0}% ของอัตรา",
    "cancelOnTheDay": "ในวันที่เช็คอิน: {0}% ของอัตรา",
    "cancelOnTheDayFree": "เมื่อเช็คอินก่อน {0}: ไม่มีค่าใช้จ่าย",
    "cancelOnTheDayCharge": "ในวันเช็คอินหลัง {0}: {1}% ของอัตรา",
    "uponLocalTime": "*นโยบายการยกเลิกทั้งหมดคำนวณตามเวลาท้องถิ่น",
    "uponLocalTimeOTA": "นโยบายการยกเลิกทั้งหมดคำนวณตามเวลาท้องถิ่น",
    "ifBookedElsewhere": "หากคุณทำการจองโดยเว็บไซต์หรือตัวแทนการท่องเที่ยวอื่น โปรดยกเลิกจากแหล่งเดิมที่คุณใช้",
    "specialRate": "อัตราการยกเลิกพิเศษเป็น: {0}",
    "guest": "แขก"
  },
  "calendar": {
    "fullyBooked": "จองเต็ม",
    "roomsLeft": "#ห้องเหลือ",
    "selected": "เลือกแล้ว",
    "unavailable": "ไม่พร้อมใช้งาน",
    "unavailableNow": "ไม่สามารถใช้งานได้ในขณะนี้"
  },
  "corporateAdmin": {
    "admin": "ผู้ดูแลระบบ",
    "checkInDate": "วันที่เช็คอิน",
    "download": "ดาวน์โหลด",
    "guestName": "ชื่อการจอง",
    "hotelName": "ชื่อโรงแรม",
    "maxNumberStay": "จำนวนการเข้าพักสูงสุด",
    "maxNumberStays": "จำนวนการเข้าพักสูงสุด",
    "nMonths": "{n} เดือน | {n} เดือน | {n} เดือน",
    "nights": "คืน",
    "numberOfRooms": "ห้องพัก",
    "remaining": "ที่เหลืออยู่",
    "reservationData": "ข้อมูลการจอง",
    "reservationDataDesc1": "※ รายละเอียดการใช้งานแสดงจำนวนคืนทั้งหมดที่จองโดยใช้เว็บไซต์ของเรา ดังนั้น โปรดทราบว่าการจองอื่นๆ (โทรศัพท์ ฯลฯ) จะไม่สะท้อนให้เห็น",
    "reservationDataDesc2": "※ อาจใช้เวลาหลายนาทีนับจากเวลาที่คุณทำการจองจนกว่าเนื้อหาจะปรากฏในหน้านี้",
    "reservationDataDesc3": "※ การเปลี่ยนแปลง/การยกเลิกที่ทำนอกเว็บไซต์ของเราจะไม่ปรากฏบนหน้าจอนี้",
    "reservationDate": "วันที่จอง",
    "reservationNumber": "หมายเลขการจอง",
    "reserved": "ที่สงวนไว้",
    "status": "สถานะ",
    "title": "ผู้ดูแลระบบองค์กร",
    "totalPrice": "ประเมิน",
    "period": "ระยะเวลา",
    "year": "ปี"
  },
  "creditCardForm": {
    "enterYourPaymentDetails": "กรอกรายละเอียดการชำระเงินของคุณ",
    "addNewCard": "บัตรเครดิตใหม่",
    "cardExpiration": "วันหมดอายุ",
    "cardExpirationConfirm": "กรุณายืนยันวันหมดอายุ",
    "cardHolder": "ชื่อผู้ถือบัตร",
    "cardHolderConfirm": "กรุณายืนยันชื่อผู้ถือบัตร",
    "cardHolderFormatError": "กรุณากรอกชื่อของคุณด้วยตัวอักษรโรมันแบบครึ่งความกว้าง",
    "cardHolderHelpOTA": "ป้อนชื่อเต็มตามที่ปรากฏบนบัตรของคุณ",
    "cardHolderHelp": "กรอกชื่อนามสกุลที่พิมพ์อยู่ในบัตรของคุณ",
    "cardNumber": "หมายเลขบัตร",
    "cardNumberConfirm": "กรุณายืนยันหมายเลขบัตร",
    "cardNumberHelp": "กรอกหมายเลขบัตรของคุณ",
    "cardRequired": "ข้อมูลบัตรเครดิตของคุณใช้เพื่อรับประกันการจองและเรียกเก็บค่าธรรมเนียมการยกเลิกตาม {0} เท่านั้น",
    "cardSecurityCode": "รหัสรักษาความปลอดภัย",
    "cardSecurityCodeConfirm": "กรุณายืนยันรหัสความปลอดภัย",
    "cardSecurityCodeHelp": "ซีวีวี",
    "ccvExampleOTA": "หมายเลข 3 หรือ 4 หลักที่อยู่ด้านหลังบัตร (Visa, Mastercard, JCB และ Diners Club) หรือที่ด้านหน้าบัตร (American Express)",
    "ccvExample": "เป็นตัวเลข 3 หรือ 4 หลักพิมพ์อยู่ด้านหลังบัตร (Visa, Mastercard, JCB) หรือด้านหน้าบัตร (American Express) ใช้สำหรับการซื้อสินค้าออนไลน์เพื่อยืนยันว่าคุณเป็นผู้ถือบัตรที่ถูกต้องและป้องกันการฉ้อโกง",
    "creditCard": "บัตรเครดิต",
    "expiredError": "กรุณาลงทะเบียนบัตรเครดิตที่ถูกต้องก่อนวันเช็คเอาท์",
    "month": "เดือน",
    "payInAdvanceOTA": "การชำระเงินด้วยบัตรเครดิตจะดำเนินการโดย {0}",
    "payInAdvance": "ชำระเงินล่วงหน้าด้วยบัตรเครดิต ดำเนินการโดย {0}",
    "payInAdvanceVoucher": "ชำระเงินล่วงหน้าด้วยบัตรเครดิต ประมวลผลโดย {0}",
    "payByCreditCard": "การชำระเงินจะถูกชำระโดยตัวแทนการท่องเที่ยว ({0}) เมื่อการจองเสร็จสมบูรณ์",
    "payByCreditCardConsent": "ข้อมูลส่วนบุคคลของคุณจะถูกแบ่งปันกับตัวแทนการท่องเที่ยว tripla Co., Ltd. ในการดำเนินการจองนี้ คุณตกลงที่จะแบ่งปันข้อมูลของคุณกับ {0}, {1} และ {2}",
    "tappayNote": "เราใช้ระบบธุรกรรม TapPay 2048-bit SLL เราไม่บันทึกรายละเอียดบัตรลูกค้าใด ๆ TapPay รักษาความปลอดภัยข้อมูลบัตรเครดิตของคุณโดยได้รับการรับรองว่าเป็นไปตามมาตรฐาน PCI DSS ที่ผู้ให้บริการระดับ 1",
    "year": "ปี",
    "cardBrand": "ประเภทบัตร",
    "cardBrandMismatch": "หมายเลขบัตรไม่ตรงกับประเภทบัตรที่เลือก",
    "paymentOverMillionError": "VISA และ Mastercard ไม่สามารถใช้ชำระเงินออนไลน์เกิน 1 ล้านเยนได้ โปรดใช้บัตรยี่ห้ออื่นหรือเลือกประเภทการชำระเงินอื่น",
    "badCards": "เนื่องจากการเปลี่ยนแปลงระบบ บัตรเครดิตต่อไปนี้ไม่สามารถใช้สำหรับการจองใหม่ได้ กรุณากรอกข้อมูลบัตรอีกครั้ง",
    "gmoBadCards": "เนื่องจากการเปลี่ยนแปลงของระบบ เราจึงปิดการใช้งานตัวเลือกในการบันทึกหรือเลือกบัตรเครดิตเดิมชั่วคราว สำหรับการชำระด้วยบัตรเครดิต กรุณากรอกรายละเอียดบัตรทุกครั้ง เราต้องขออภัยในความไม่สะดวกและขอขอบคุณสำหรับความเข้าใจของคุณ",
    "pontaVisaCheckbox": "เมื่อใช้บัตร Route Inn Hotels PontaVISA โปรดเลือก 'บัตร Route Inn Hotels PontaVISA' เป็นแบรนด์บัตร หากคุณเลือก 'วีซ่า' จะไม่ได้รับคะแนนโบนัส",
    "creditCardInvalid": "ไม่ถูกต้อง",
    "creditCardInvaildHoverText": "เนื่องจากการเปลี่ยนแปลงระบบ บัตรเครดิตต่อไปนี้ไม่สามารถใช้สำหรับการจองใหม่ได้ กรุณากรอกข้อมูลบัตรอีกครั้ง",
    "rrn": "",
    "firstTwoDigitOfPin": "",
    "payDirectlyOnsitePayment": "ชำระเงินโดยตรงที่โรงแรมด้วยการชำระเงิน ณ ที่พัก",
    "payByLocalCurrency": "ชำระเงินด้วยสกุลเงินท้องถิ่นโดยใช้บัตร JCB, Amex หรือ Diners ของคุณ หรือจ่ายโดยตรงที่โรงแรมด้วยการชำระเงิน ณ ที่พัก",
    "exploreLocalCurrency": "หรือสำรวจตัวเลือกการชำระเงินอื่นในสกุลเงินท้องถิ่น",
    "exploreOther": "หรือสำรวจตัวเลือกการชำระเงินอื่น"
  },
  "gmoPaypalOptions": {
    "null": "เลือกประเภทบัตร",
    "visa": "วีซ่า",
    "ponta_visa": "VISA (บัตรโรงแรม Route Inn Hotels PontaVISA)",
    "master": "มาสเตอร์การ์ด",
    "mastercard": "มาสเตอร์การ์ด",
    "amex": "อเมริกันเอ็กซ์เพรส",
    "jcb": "เจซีบี",
    "diners": "ไดเนอร์สคลับ"
  },
  "downloadPasswordModal": {
    "label": "กรุณากรอกรหัสผ่านการเข้ารหัส การจองจะถูกซิปโดยใช้รหัสผ่าน"
  },
  "extraItems": {
    "error": {
      "extraNumber": "กรุณาเปลี่ยนหมายเลขของ {name} จำนวนที่สามารถจองได้คือ {min} ~ {max}"
    }
  },
  "extras": {
    "dateSelectTitle": "เลือกวันที่เพื่อเพิ่มรายการพิเศษ",
    "dateSelectBtn": "ดำเนินการตามปริมาณ",
    "goToGuestDetails": "ป้อนรายละเอียดแขก",
    "stock": "คลังสินค้า:",
    "selectableQuantity": "ปริมาณที่เลือกได้",
    "remainingExtras": "สิ่งพิเศษที่เหลืออยู่"
  },
  "facilitySuggestion": {
    "distanceFrom": "ระยะทาง: {n} กม. จากสถานที่นี้",
    "from": "จาก",
    "header": "ห้องว่างอื่นๆ ใกล้โรงแรมนี้",
    "soldOut": "ขายหมดแล้ว. กรุณาเปลี่ยนความต้องการของคุณและค้นหาอีกครั้ง",
    "soldOutDesc": "วันที่ของคุณเป็นที่นิยม ห้องพักในที่พักแห่งนี้หมดแล้ว! ลองปรับเปลี่ยนวันที่ของคุณหรือเลือกที่พักอื่น",
    "visitHotelWebsite": "เยี่ยมชมเว็บไซต์ของโรงแรม"
  },
  "guestDetail": {
    "afterUpdate": "อัปเดตแล้ว",
    "bookingForSomeone": "ฉันกำลังจองให้คนอื่น",
    "brrDiscount": "ส่วนลดค่าห้องพักที่ดีที่สุด",
    "confirmUpdates": "ยืนยันการอัปเดต",
    "confirmPayByCard": "ยืนยันการชำระเงินด้วยบัตรเครดิต",
    "coupon": "คูปอง",
    "couponApplied": "ใช้แล้ว",
    "couponAppliedNew": "คูปองที่ใช้:",
    "creansmaerdCouponApplied": "ปัจจุบันใช้ชื่อคูปอง {name}",
    "couponCode": "รหัสคูปอง",
    "couponDiscount": "คูปองส่วนลด",
    "couponExpired": "ขออภัย รหัสคูปองของคุณใช้ไม่ได้อีกต่อไป",
    "couponInvalid": "รหัสคูปองที่คุณป้อนไม่ถูกต้อง",
    "couponLimitReached": "คูปอง {code} นี้ไม่สามารถใช้อีกต่อไปได้เนื่องจากถึงขีดจำกัดการใช้งานแล้ว",
    "couponMaxStays": "ส่วนลดของคูปองนี้สามารถใช้ได้สูงสุด {n} คืนแรกเท่านั้น คืนใดๆ หลังจากคืน {n} คืนแรกจะถูกเรียกเก็บเงินในราคาปกติ",
    "couponTargetPlans": "แผนเป้าหมายรหัสคูปอง",
    "couponNotUse": "อย่าใช้คูปองใดๆ",
    "couponValidUntil": "ใช้ได้ถึงวันที่ {date}",
    "couponUsageQuantityLimit": "สามารถใช้รหัสคูปองได้ครั้งละหนึ่งรหัสเท่านั้น โปรดลบรหัสที่เพิ่มไว้แล้วเพื่อใช้รหัสใหม่",
    "creditCardWarning": "ธุรกรรมการชำระเงินเดิมของบัตรเครดิตของคุณจะถูกยกเลิก และจำนวนเงินที่ชำระทั้งหมดใหม่จะถูกหักจากบัตรเครดิตของคุณ",
    "availableCoupons": "คูปองที่มีอยู่",
    "viewMoreCoupons": "ดูคูปองเพิ่มเติม",
    "removeCoupon": "ลบ",
    "day": "วัน {n}",
    "email": "อีเมล (โปรดอนุญาตให้รับอีเมลจาก {email})",
    "emailAgain": "อีเมล์ (ยืนยัน)",
    "emailAgainConfirm": "\"อีเมล\" และ \"การยืนยันอีเมล\" ไม่ตรงกัน",
    "emailAgainHelp": "ป้อนที่อยู่อีเมลของคุณอีกครั้ง",
    "emailConfirm": "กรุณากรอกที่อยู่อีเมลที่ถูกต้อง",
    "emailHelp": "กรอกที่อยู่อีเมล์ของคุณ",
    "extras": "บริการพิเศษ",
    "extraLeft": "เหลืออีก {count} {unit}",
    "extraOutOfStock": "สินค้าหมด",
    "fullName": "ชื่อเต็ม",
    "fullNameError": "กรุณาระบุชื่อเต็มเป็นภาษาอังกฤษ",
    "goPreviousScreenConfirm": "กลับไปที่หน้าจอก่อนหน้า?",
    "goPreviousScreenDescription": "โปรดทราบว่าข้อมูลใด ๆ ที่ป้อนในหน้าปัจจุบันจะไม่ได้รับการบันทึก",
    "goPreviousScreenContinue": "ดำเนินการต่อ",
    "guestDetails": "รายละเอียดผู้เข้าพัก",
    "guestName": "ชื่อแขก",
    "guestNameError": "กรุณาระบุชื่อเต็มเป็นภาษาอังกฤษ",
    "guestNameHelp": "กรอกชื่อเต็มของคุณ",
    "guestNameKanji": "ชื่อแขก (คันจิ)",
    "guestStayName": "ชื่อแขก",
    "mainGuest": "การติดต่อที่สำคัญ",
    "notes": "หมายเหตุ",
    "originalPaymentAmount": "จำนวนเงินที่ชำระเดิม",
    "promotionDiscount": "การลดราคา",
    "phone": "โทรศัพท์",
    "phoneConfirm": "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง อนุญาตให้ใช้เฉพาะตัวเลขและ + เท่านั้น",
    "phoneHelp": "ป้อนหมายเลขโทรศัพท์ของคุณ",
    "priceBecomeCheaper": "ราคาของแผนที่คุณเลือกมีราคาถูกลงแล้วเนื่องจากโรงแรมอัปเดตโปรโมชั่น โปรดตรวจสอบราคาที่อัปเดตและดำเนินการจองของคุณ",
    "priceBecomeExpensive": "ราคาของแผนที่คุณเลือกมีราคาแพงขึ้นในขณะนี้เนื่องจากโรงแรมอัปเดตโปรโมชั่น โปรดตรวจสอบราคาที่อัปเดตอย่างรอบคอบและดำเนินการจองของคุณ",
    "priceDiff": "ส่วนต่างราคา",
    "signupTerms": "ระบบการจองนี้ให้บริการโดย tripla ข้อมูลส่วนบุคคลของคุณจะถูกแบ่งปันกับ tripla ในการดำเนินการลงทะเบียนสมาชิกนี้ ฉันยอมรับ {0}, {1} และ {2} ของ tripla",
    "terms": "เมื่อทำการจองนี้เสร็จสิ้น คุณรับทราบว่าข้อมูลส่วนบุคคลของคุณจะถูกแบ่งปันกับบริษัท ทริปลา จำกัด ซึ่งเป็นบริษัทที่ให้บริการระบบการจองนี้ คุณตกลงที่จะแบ่งปันข้อมูลของคุณกับ tripla {0}, {1} และ {2}",
    "totalAmount": "ยอดรวม",
    "totalAmountWithTax": "ยอดรวม (รวมภาษี)",
    "totalWithoutTax": "ยอดรวม (ไม่รวมภาษี)",
    "updateUserInformation": "อัปเดตโปรไฟล์สมาชิกของฉันด้วยการเปลี่ยนแปลงข้างต้น ยกเว้นอีเมล",
    "cheaperAccommodationTax": "ภาษีที่พักสำหรับการจองของคุณมีราคาถูกลงตั้งแต่คุณแลกคะแนนสะสม โปรดตรวจสอบราคาที่อัปเดตและดำเนินการจองของคุณ",
    "roomGuestName": "ห้อง {0} ชื่อแขก",
    "confirmation": {
      "title": "ช่องที่มีเครื่องหมาย {0} จะต้องกรอกแบบฟอร์ม",
      "description": "ไม่สามารถเปลี่ยนชื่อผู้เข้าพักได้หลังจากทำการจองแล้ว โปรดตรวจสอบให้แน่ใจว่าได้กรอกชื่อของผู้ที่จะเข้าพัก"
    }
  },
  "inventoryCalendar": {
    "availableIconDescription": "{icon}: ห้องว่าง",
    "unavailableIconDescription": "{icon}: ไม่มีห้องว่าง",
    "uncertainIconDescription": "{icon}: มีจำนวนจำกัด"
  },
  "lineLogin": {
    "buttonText": "เข้าสู่ระบบด้วยไลน์"
  },
  "meta": {
    "facilities": "สิ่งอำนวยความสะดวกของแบรนด์",
    "sign_in": "เข้าสู่ระบบ",
    "organization_sign_in": "เข้าสู่ระบบองค์กร",
    "sign_up": "ลงชื่อ",
    "search_results": "ผลการค้นหา",
    "guest_detail": "รายละเอียดแขก",
    "payment_method": "การชำระเงิน",
    "lotteryCompleted": "การยืนยันการเข้าลอตเตอรี",
    "completed": "การยืนยัน",
    "reservationIncomplete": "การจองที่ไม่สมบูรณ์",
    "my_account": "หน้าของฉัน",
    "otaHome": "ตัวเลือกโรงแรมอัจฉริยะพร้อมการค้นหาที่ขับเคลื่อนด้วย AI",
    "otaHomeDescription": "สำรวจที่พักที่ได้รับการคัดเลือกมากกว่า 2,000 แห่งด้วย tripla ขับเคลื่อนโดยการค้นหา AI จากเรียวกังแบบดั้งเดิมไปจนถึงรีสอร์ทหรูหรา ค้นหาการเข้าพักที่สมบูรณ์แบบของคุณได้อย่างง่ายดาย",
    "otaSearchHotels": "ผลการค้นหาโรงแรม",
    "otaSearchResults": "ผลการค้นหา",
    "otaGuestDetail": "รายละเอียดแขก",
    "otaPayment": "การชำระเงิน",
    "otaConfirmation": "การยืนยัน",
    "otaBookingConfirmation": "การยืนยันการจอง"
  },
  "membership": {
    "promotionRate": "อัตราโปรโมชั่น",
    "regularRate": "อัตราปกติ",
    "signUpFor": "อัตราสมาชิก"
  },
  "membershipForm": {
    "address": "ที่อยู่",
    "alreadyHaveAccount": "มีบัญชีอยู่แล้ว",
    "back": "กลับ",
    "buildingName": "ชื่ออาคารและหมายเลขห้อง",
    "changePassword": "เปลี่ยนรหัสผ่าน",
    "changePasswordResult": "รหัสผ่านของคุณมีการเปลี่ยนแปลง",
    "changePasswordResult02": "✓ อัปเดตรหัสผ่านของคุณสำเร็จแล้ว",
    "changePasswordResult03": "คลิกลงชื่อเข้าใช้เพื่อลงชื่อเข้าใช้ด้วยรหัสผ่านใหม่ของเรา",
    "changePasswordSecondary": "กรุณากรอกรหัสผ่านใหม่ของคุณ",
    "confirmDeletedResult": "บัญชีของคุณถูกลบเรียบร้อยแล้ว",
    "confirmNewPassword": "ยืนยันรหัสผ่านใหม่",
    "confirmPassword": "ยืนยันรหัสผ่าน",
    "confirmRegisterFail": "บางอย่างผิดพลาด",
    "confirmRegisterFail02": "กรุณาลงทะเบียนอีกครั้ง",
    "confirmRegisterResult": "การตรวจสอบที่อยู่อีเมล",
    "confirmRegisterResult02": "✓ ลงทะเบียนเรียบร้อยแล้ว",
    "confirmRegisterResult03": "เพลิดเพลินไปกับการพักผ่อนของคุณ",
    "createAccount2": "สร้างบัญชีของคุณ",
    "createAccount3": "สร้างบัญชี",
    "createAccount4": "สร้างบัญชีใหม่",
    "dob": "วันเกิด",
    "email": "อีเมล",
    "emailError": "กรุณายืนยันอีเมลของคุณ",
    "emailPromotionCheck": "รับอีเมลส่งเสริมการขายจากโรงแรม",
    "english_error": "กรุณากรอกเป็นภาษาอังกฤษ",
    "locale_error": {
      "ja": "กรุณากรอกเป็นภาษาอังกฤษ",
      "zh_Hant": "กรุณากรอกเป็นภาษาอังกฤษ",
      "ko": "กรุณากรอกเป็นภาษาอังกฤษ"
    },
    "enterDetail": "กรอกรายละเอียดของคุณด้านล่าง",
    "first_name": "ชื่อจริง",
    "firstNameLocales": {
      "ja": "ชื่อจริง",
      "zh_Hant": "ชื่อจริง",
      "ko": "ชื่อจริง"
    },
    "first_name_kanji": "",
    "first_name_kanji_placeholder": "",
    "first_name_placeholder": "จอห์น",
    "firstNamePlaceHolderLocales": {
      "ja": "จอห์น",
      "zh_Hant": "จอห์น",
      "ko": "จอห์น"
    },
    "firstName": "ชื่อจริง",
    "firstNameError": "กรุณายืนยันชื่อของคุณ",
    "firstNameKana": "ชื่อจริง",
    "forgotPassword": "ลืมรหัสผ่านหรือไม่",
    "forgotPassword02": "รีเซ็ตรหัสผ่าน",
    "forgotPasswordError": "ไม่มีผู้ใช้ที่ตรงกับอีเมลที่ให้ไว้",
    "forgotPasswordResult": "รีเซ็ตรหัสผ่าน",
    "forgotPasswordResult02": "✓ ส่งอีเมลรีเซ็ตรหัสผ่านแล้ว",
    "forgotPasswordResult02New": "✓ ได้รับคำขอรีเซ็ตรหัสผ่านสำหรับ {email}",
    "forgotPasswordResultSecondary": "อีเมลรีเซ็ตรหัสผ่านได้ถูกส่งไปยังที่อยู่อีเมลในไฟล์สำหรับบัญชีของคุณแล้ว แต่อาจใช้เวลาหลายนาทีจึงจะปรากฏในกล่องจดหมายของคุณ โปรดรออย่างน้อย 10 นาทีก่อนที่จะลองรีเซ็ตอีกครั้ง",
    "forgotPasswordResultSecondaryNew": "หากอีเมลที่คุณป้อนเชื่อมโยงกับบัญชี คุณจะได้รับอีเมลรีเซ็ตรหัสผ่านในไม่ช้า อาจใช้เวลาถึง 10 นาทีก่อนที่ข้อความจะมาถึง โปรดตรวจสอบโฟลเดอร์สแปมหรือขยะของคุณก่อนที่จะพยายามรีเซ็ตอีกครั้ง",
    "forgotPasswordResultSecondary02New": "หากคุณไม่ได้รับอีเมลภายใน 10 นาที คุณอาจไม่มีบัญชี ลองอีเมลอื่นหรือ {0}",
    "forgotPasswordSecondary": "กรุณากรอกอีเมลของคุณ. เราจะส่งลิงก์รีเซ็ตไปยังบัญชีอีเมลของคุณ",
    "gender": "เพศ",
    "generalError": "เกิดข้อผิดพลาดระหว่างการประมวลผล. กรุณาลองใหม่อีกครั้งในภายหลัง.",
    "guest_first_name": "ชื่อจริง",
    "guest_first_name_kanji": "",
    "guest_last_name": "นามสกุล",
    "guest_last_name_kanji": "",
    "header": "สร้างบัญชี",
    "hiragana_error": "",
    "kanji_katakana_error": "กรุณากรอกตัวอักษรคันจิ ฮิระงะนะ คาตาคานะ หรือภาษาอังกฤษ",
    "kanji_error": "",
    "last_name": "นามสกุล",
    "lastNameLocales": {
      "ja": "นามสกุล",
      "zh_Hant": "นามสกุล",
      "ko": "นามสกุล"
    },
    "last_name_kanji": "",
    "last_name_kanji_placeholder": "",
    "last_name_placeholder": "สมิธ",
    "lastNamePlaceHolderLocales": {
      "ja": "สมิธ",
      "zh_Hant": "สมิธ",
      "ko": "สมิธ"
    },
    "lastName": "นามสกุล",
    "lastNameError": "กรุณายืนยันนามสกุลของคุณ",
    "lastNameKana": "นามสกุล",
    "membershipId": "หมายเลขสมาชิก",
    "membershipProgram": "โปรแกรมสมาชิก",
    "name": "ชื่อ",
    "nameJaPlaceholder": "กรุณากรอกตัวอักษรคันจิ ฮิระงะนะ คาตาคานะ หรือภาษาอังกฤษ",
    "nameJaValidation": "กรุณากรอกตัวอักษรคันจิ ฮิระงะนะ คาตาคานะ หรือภาษาอังกฤษ",
    "nameInvalidCharMessage": "ข้อมูลที่คุณระบุมีอักขระที่ระบบของเราไม่รองรับ ",
    "nameKanaJaPlaceholder": "กรุณากรอกฮิระงะนะ",
    "namePlaceholder": {
      "en": "กรุณากรอกภาษาอังกฤษ",
      "ja": "กรุณากรอกฮิระงะนะหรือภาษาอังกฤษ",
      "zh_Hant": "กรุณากรอกตัวอักษรคันจิหรือภาษาอังกฤษ",
      "zh_Hans": "กรุณากรอกตัวอักษรคันจิหรือภาษาอังกฤษ",
      "ko": "กรุณากรอกอังกูลหรือภาษาอังกฤษ"
    },
    "nameValidation": {
      "ja": "กรุณากรอกฮิระงะนะหรือภาษาอังกฤษ",
      "zh_Hant": "กรุณากรอกตัวอักษรคันจิหรือภาษาอังกฤษ",
      "zh_Hans": "กรุณากรอกตัวอักษรคันจิหรือภาษาอังกฤษ",
      "ko": "กรุณากรอกอังกูลหรือภาษาอังกฤษ"
    },
    "nationality": "สัญชาติ",
    "newPassword": "รหัสผ่านใหม่ (อย่างน้อย 8 ตัวอักษรพร้อมตัวอักษรตัวพิมพ์ใหญ่และตัวพิมพ์เล็กและตัวเลข)",
    "noAccount": "คุณยังไม่มีบัญชี?",
    "occupation": "อาชีพ",
    "or": "หรือ",
    "orContinueBelow": "หรือดำเนินการต่อด้านล่าง",
    "password": "รหัสผ่าน",
    "passwordConfirmationError": "รหัสผ่านของคุณและการยืนยันไม่ตรงกัน",
    "passwordError": "กรุณายืนยันรหัสผ่านของคุณ",
    "passwordSignUp": "รหัสผ่าน (อย่างน้อย 8 ตัวอักษรพร้อมตัวอักษรตัวพิมพ์ใหญ่และตัวพิมพ์เล็กและตัวเลข)",
    "passwordNote": "อย่างน้อย 8 ตัวอักษรพร้อมตัวอักษรและตัวเลขตัวพิมพ์ใหญ่และตัวพิมพ์เล็ก",
    "pleaseSelect": "เลือกโปรแกรม",
    "postCode": "รหัสไปรษณีย์",
    "postCodeError": "อนุญาตให้ใช้เฉพาะตัวอักษรและตัวเลขเท่านั้น",
    "prefecture": "จังหวัด",
    "notApplicable": "ไม่สามารถใช้ได้",
    "privacyPolicy": "นโยบายความเป็นส่วนตัว",
    "promotionsCheckbox": "ฉันต้องการรับข้อเสนอพิเศษและโปรโมชั่นทางอีเมล",
    "resend": "ส่งซ้ำ",
    "resendConfirmationEmail": "ไม่ได้รับอีเมล์ยืนยัน?",
    "resendConfirmationEmailError": "อีเมลนี้ยังไม่เคยลงทะเบียนมาก่อน",
    "resendConfirmationEmailTitle": "ส่งอีเมลยืนยันอีกครั้ง",
    "receiptName": "ชื่อใบเสร็จรับเงิน",
    "receiptUnicode": "",
    "send": "ส่ง",
    "setPassword": "ตั้งรหัสผ่าน",
    "setup": "สร้างบัญชี",
    "setupResultSecondary01": "หากคุณต้องการลองสมัครสมาชิกใหม่อีกครั้ง โปรดลองอีกครั้งจาก {0}",
    "setupResultSecondary02": "เราได้ส่งอีเมลเพื่อยืนยันที่อยู่อีเมลที่คุณลงทะเบียน\nเนื่องจากนี่เป็นการลงทะเบียนชั่วคราว โปรดเข้าถึง URL ในอีเมลเพื่อดำเนินการสมัครสมาชิกให้เสร็จสิ้น\nคุณสามารถเข้าสู่ระบบได้หลังจากเสร็จสิ้นการลงทะเบียน",
    "signIn": "เข้าสู่ระบบ",
    "signInHere": "ลงชื่อเข้าใช้ที่นี่",
    "signInTo": "ลงชื่อเข้าใช้ {0}",
    "signUp": "สร้างบัญชี",
    "signUpResult": "ยืนยันที่อยู่อีเมลของคุณ",
    "signUpResult02": "ลงทะเบียนเรียบร้อยแล้ว",
    "signUpResult02Secondary": "ขอขอบคุณที่ลงทะเบียนกับ {n}",
    "signUpResult02Secondary02": "อีเมลยืนยันได้ถูกส่งไปยังที่อยู่อีเมลที่คุณให้ไว้",
    "signUpResultSecondary": "ขอขอบคุณที่ลงทะเบียนกับ {n}",
    "signUpResultSecondary02": "หากต้องการใช้บัญชีของคุณ คุณต้องยืนยันที่อยู่อีเมลที่คุณให้ไว้",
    "signUpResultSecondary03": "โปรดตรวจสอบเนื้อหาและคลิก URL ต่อไปนี้",
    "signUpResultSecondary04": "การสมัครสมาชิกจะเสร็จสิ้นหลังจากยืนยันที่อยู่อีเมลของคุณ",
    "signUpSecondary": "สร้างบัญชีของคุณ ใช้เวลาไม่ถึงหนึ่งนาที",
    "term": "การสมัครแสดงว่าคุณยอมรับ {0} และ {1}",
    "termError": "กรุณายอมรับข้อกำหนดและเงื่อนไข",
    "termOfUse": "ข้อกำหนดการใช้งาน",
    "wifi": "ฟรีไวไฟในห้องพัก",
    "yetReceived": "ไม่ได้รับอีเมลใช่ไหม? {0}",
    "resendEmail": "ส่งอีเมลอีกครั้ง",
    "pleaseResetPassword": "การลงทะเบียนบัญชีอีเมลของคุณได้รับการยืนยันแล้ว โปรดทราบว่าไม่จำเป็นต้องส่งอีเมลยืนยันอีกครั้ง\nหากคุณทราบรหัสผ่านของคุณ โปรด {0} โดยตรง\nหรือหากคุณลืมรหัสผ่าน โปรดใช้ตัวเลือก {1}",
    "pleaseResetPasswordLink1": "เข้าสู่ระบบ",
    "pleaseResetPasswordLink2": "รีเซ็ตรหัสผ่าน",
    "receiptNameFormatError": "",
    "receiptUnicodeFormatError": "",
    "receiptNameRequiredError": "",
    "receiptUnicodeRequiredError": ""
  },
  "membersihp": {
    "target": "เป้าหมายการเป็นสมาชิก"
  },
  "multipleCurrency": {
    "JPY": "เยนญี่ปุ่น",
    "ARS": "เปโซอาร์เจนตินา",
    "AUD": "ดอลลาร์ออสเตรเลีย",
    "BRL": "เรียลบราซิล",
    "CAD": "ดอลลาร์แคนาดา",
    "CNY": "หยวนจีน",
    "CZK": "โครูนาเช็ก",
    "DKK": "โครนเดนมาร์ก",
    "EUR": "ยูโร",
    "HKD": "ดอลลาร์ฮ่องกง",
    "INR": "รูปีอินเดีย",
    "IDR": "รูเปียห์ชาวอินโดนีเซีย",
    "ILS": "นิวเชเกลของอิสราเอล",
    "MOP": "มาเก๊า ปาคาต้า",
    "MYR": "ริงกิตมาเลเซีย",
    "MXN": "เปโซเม็กซิกัน",
    "TWD": "ดอลลาร์ไต้หวันใหม่",
    "NZD": "ดอลลาร์นิวซีแลนด์",
    "NOK": "โครนนอร์เวย์",
    "PHP": "เปโซฟิลิปปินส์",
    "GBP": "ปอนด์สเตอร์ลิง",
    "QAR": "กาตาร์ ริยัล",
    "RUB": "รูเบิลรัสเซีย",
    "SAR": "ริยัลซาอุดีอาระเบีย",
    "SGD": "ดอลลาร์สิงคโปร์",
    "ZAR": "แรนด์แอฟริกาใต้",
    "KRW": "วอนเกาหลีใต้",
    "LKR": "รูปีศรีลังกา",
    "SEK": "โครนสวีเดน",
    "CHF": "ฟรังก์สวิส",
    "THB": "เงินบาท",
    "AED": "สหรัฐอาหรับเอมิเรตส์ เดอร์แฮม",
    "USD": "ดอลลาร์สหรัฐ",
    "VND": "ดองเวียดนาม",
    "modalTitle": "เลือกสกุลเงินของคุณ",
    "modalDescription": "โปรดเลือกสกุลเงิน หากคุณเลือกสกุลเงินอื่นที่ไม่ใช่เยนญี่ปุ่น วิธีการชำระเงินของคุณจะถูกจำกัดไว้เฉพาะบัตรเครดิต",
    "suggestedCurrencies": "สกุลเงินที่ใช้กันทั่วไป",
    "allCurrencies": "ทุกสกุลเงิน",
    "creditCardWarning": "สกุลเงินมีการเปลี่ยนแปลงเนื่องจากการเปลี่ยนแปลงวิธีการชำระเงิน กรุณาตรวจสอบจำนวนเงิน",
    "exchangeRateUpdatedError": "อัตราแลกเปลี่ยนได้รับการอัปเดต ดังนั้นโปรดตรวจสอบราคาล่าสุดและทำการจอง",
    "currency": "สกุลเงิน"
  },
  "myAccount": {
    "availablePoints": "มีจำหน่าย {0}",
    "availablePointsDesc": "จำนวนเงินทั้งหมด {0} ที่คุณถืออยู่และสามารถใช้ได้",
    "totalRankingPoints": "คุณมี {amount} อันดับ {pointName}",
    "changeLoginEmail": "เปลี่ยนอีเมล์เข้าสู่ระบบ",
    "changeLoginEmailDescription": "หากต้องการอัปเดตอีเมลของคุณ โปรดเปลี่ยนด้านล่าง คุณจะได้รับอีเมลยืนยัน หากคุณมีปัญหาในการรับอีเมล คุณสามารถเปลี่ยนที่อยู่อีเมลของคุณและลองอีกครั้งได้บ่อยเท่าที่คุณต้องการ",
    "changeLoginEmailPendingText": "ที่อยู่อีเมลของคุณได้รับการอัปเดตแล้วและกำลังรอการตรวจสอบ",
    "changePassword": "รีเซ็ตรหัสผ่าน",
    "confirmLoginEmail": "รออีเมลใหม่",
    "edit": "แก้ไข",
    "emailLabel": "อีเมล",
    "loginEmail": "อีเมล์เข้าสู่ระบบ",
    "loginInfo": "ข้อมูลการเข้าสู่ระบบ",
    "notRegistered": "ยังไม่ได้ลงทะเบียน",
    "pageTitle": "บัญชี",
    "password": "รหัสผ่าน",
    "passwordLabel": "รหัสผ่าน",
    "paymentInfo": "ข้อมูลการชำระเงิน",
    "paymentOptions": "ตัวเลือกการชำระเงิน",
    "paymentOptionsDesc": "นี่คือรายการบัตรเครดิตที่คุณลงทะเบียนไว้ ข้อมูลทั้งหมดที่เกี่ยวข้องกับบัตรเครดิตจะได้รับการจัดการอย่างเป็นความลับอย่างเข้มงวด",
    "pendingEmailText": "{n} กำลังรอการตรวจสอบ",
    "personalInfo": "ข้อมูลส่วนบุคคล",
    "promotions": "คูปอง",
    "point": "คะแนน",
    "removeAccount": "ปิดบัญชี",
    "removeAccountConfirmationText": "คุณไม่สามารถกู้คืนบัญชีของคุณได้เมื่อถูกลบแล้ว คุณแน่ใจไหม?",
    "removeAccountReason01": "ไม่ได้ใช้อีกต่อไป",
    "removeAccountReason02": "ค่าห้องแพง",
    "removeAccountReason03": "สิทธิประโยชน์ของสมาชิกไม่น่าดึงดูด",
    "removeAccountReasonInput": "พิมพ์เหตุผลของคุณ",
    "removeAccountReasonOthers": "อื่นๆ (โปรดระบุเหตุผลของคุณ)",
    "removeAccountSecondaryText": "คุณต้องการลบบัญชีของคุณหรือไม่?",
    "removeAccountSecondaryText02": "โปรดทราบว่าสิ่งนี้ไม่สามารถย้อนกลับได้",
    "removeCreditCard": "ลบบัตรเครดิต",
    "removeCreditCardConfirm": "คุณแน่ใจหรือไม่ว่าต้องการลบบัตรเครดิตนี้",
    "resendEmail": "ส่งการยืนยันอีกครั้ง",
    "resendEmailSuccess": "อีเมลถูกส่งไปยังกล่องจดหมายของคุณแล้ว",
    "yourCreditCards": "บัตรเครดิตของคุณ",
    "membershipId": "รหัสสมาชิก",
    "myQRCode": "รหัส QR ของฉัน",
    "promotion": "การตั้งค่าอีเมลส่งเสริมการขาย",
    "historyStatus": {
      "expired": "คะแนนที่หมดอายุ",
      "future": "คะแนนในอนาคต",
      "spent": "คะแนนที่ใช้ไป",
      "gained": "คะแนนที่ได้รับ",
      "refunded": "คะแนนการคืนเงิน"
    },
    "value": "ค่า",
    "badCards": "เนื่องจากมีการเปลี่ยนแปลงระบบ บัตรใบนี้ไม่สามารถใช้จองใหม่ได้",
    "unlockRank": "ปลดล็อกอันดับ {rank} โดยรวบรวม {points} {pointName} ก่อน {date}",
    "unlockRankNoDate": "ปลดล็อกอันดับ {rank} โดยการรวบรวม {points} {pointName}",
    "earnMorePointsToKeepRank": "รับ {points} เพิ่มขึ้น {pointName} ก่อนวันที่ {date} เพื่อรักษาอันดับ {rank}",
    "achievedHighest": "ยินดีด้วย! คุณได้รับตำแหน่งสูงสุดแล้ว",
    "achievedHighestOnDate": "ยินดีด้วย! คุณได้ปฏิบัติตามเงื่อนไขและจะบรรลุอันดับสูงสุดในวันที่ {date}"
  },
  "myProfile": {
    "cancel": "ยกเลิกการจองของคุณ",
    "cancelled": "ยกเลิก",
    "change": "เปลี่ยน",
    "checkIn": "เช็คอิน",
    "checkOut": "เช็คเอาท์",
    "confirmationNo": "หมายเลขการจอง",
    "multiRoom": "หลายห้อง",
    "myReservations": "การจอง",
    "myPage": "หน้าของฉัน",
    "noResultButtonText": "เริ่มวางแผนการเดินทางของคุณ",
    "noResultText": "คุณยังไม่ได้จองอะไรเลย",
    "pageTitle": "บัญชีของคุณ",
    "viewDetails": "ดูรายละเอียด",
    "viewFlightDetails": "รายละเอียดการจอง (รวมตั๋วเครื่องบิน)",
    "myVoucher": {
      "amount": "จำนวน",
      "askConsume": "ใช้คูปอง?",
      "askConsumeMessage": "คูปองนี้สามารถใช้ได้เพียงครั้งเดียวเท่านั้น",
      "cancel": "ยกเลิก",
      "consume": "บริโภค",
      "consumeVoucher": "ใช้คูปอง",
      "excludeDuring": "ยกเว้นในระหว่าง",
      "makeReservation": "ทำการจอง",
      "targetStayingPeriod": "เป้าหมายระยะเวลาการเข้าพัก",
      "validThrough": "ใช้ได้ผ่าน",
      "name": "ชื่อ",
      "consumptionTime": "เวลาการบริโภค",
      "reservationNumber": "หมายเลขการจอง",
      "status": {
        "status": "สถานะ",
        "available": "มีอยู่",
        "pending": "รอดำเนินการ",
        "expired": "หมดอายุแล้ว",
        "consumed": "บริโภค"
      },
      "selectHotel": "เลือกโรงแรม"
    },
    "lottery": {
      "status": {
        "registered": "กำลังดำเนินการ",
        "not_winner": "ไม่ได้เลือก",
        "winner_reservation_confirmed": "เลือกแล้ว(จอง)",
        "winner_reservation_cancelled": "การจองถูกยกเลิก",
        "registration_cancelled": "รายการถูกยกเลิก",
        "winner_reservation_pending_declined": "รายการถูกยกเลิก",
        "winner_reservation_pending": "เลือกแล้ว (รอการยืนยัน)"
      },
      "text": "หวย"
    },
    "processBooking": "ดำเนินการจอง",
    "cancelEntry": "ยกเลิกการเข้า",
    "lotteryEntryNo": "เลขที่เข้าสลาก"
  },
  "mcp": {
    "chooseCurrency": "เลือกสกุลเงินที่คุณต้องการ",
    "recommendLocalCurrency": "เราขอแนะนำให้ชำระเงินด้วยสกุลเงินท้องถิ่นของคุณเพื่อลดความผันผวนของอัตราแลกเปลี่ยนและความเสี่ยงจากการยกเลิก กรุณาเลือก VISA หรือ Mastercard เนื่องจากขณะนี้ยังไม่รองรับการชำระเงินด้วยสกุลเงินต่างประเทศสำหรับบัตรเครดิตที่เลือก"
  },
  "organization": {
    "enterDetail": "กรุณากรอกรหัสบัญชีและรหัสผ่านของคุณ",
    "signIn": "เข้าสู่ระบบองค์กร"
  },
  "ota": {
    "changeLoginEmailPendingText": "ที่อยู่อีเมลของคุณได้รับการอัปเดตแล้วและกำลังรอการตรวจสอบ",
    "name": {
      "agoda": "อโกด้า",
      "booking_com": "จองห้องพักคอม",
      "expedia": "เอ็กซ์พีเดีย",
      "jalan": "จาลัน",
      "rakuten_travel": "ราคุเต็น ทราเวล",
      "rakutenTravel": "ราคุเต็น ทราเวล"
    },
    "priceNotAvailable": "ไม่สามารถใช้ได้",
    "loadingTitle0": "ค้นพบการเข้าพักที่สมบูรณ์แบบของคุณ",
    "loadingDescription0": "การควบคุม AI เพื่อแนะนำโรงแรมแบบเฉพาะตัว กำลังโหลดการเข้าพักในฝันของคุณ...",
    "loadingTitle1": "ยกระดับประสบการณ์ของคุณในญี่ปุ่น",
    "loadingDescription1": "ดื่มด่ำไปกับวัฒนธรรมอันมั่งคั่งของญี่ปุ่นด้วยพิธีชงชาแบบดั้งเดิม ค้นพบศิลปะการเตรียมมัทฉะและดื่มด่ำกับความเงียบสงบ",
    "loadingTitle2": "อัญมณีที่ซ่อนอยู่รออยู่",
    "loadingDescription2": "สำรวจสิ่งมหัศจรรย์นอกเส้นทางโดยการผจญภัยในละแวกใกล้เคียงที่ไม่ค่อยมีคนรู้จัก ค้นพบสมบัติท้องถิ่นและสัมผัสเสน่ห์ที่แท้จริงของเมือง",
    "loadingTitle3": "เชื่อมต่อกับประเพณี",
    "loadingDescription3": "ดื่มด่ำไปกับความมหัศจรรย์ของย่านเกอิชา ชมการแสดงเต้นรำแบบดั้งเดิมหรือเข้าร่วมพิธีชงชาเพื่อสัมผัสวัฒนธรรมที่แท้จริง",
    "loadingTitle4": "ความสุขด้านการทำอาหาร",
    "loadingDescription4": "ดำดิ่งสู่โลกแห่งอาหารญี่ปุ่นที่หลากหลายด้วยการเยี่ยมชมตลาดอาหารข้างทางที่มีชื่อเสียงหลายแห่ง ตั้งแต่ซูชิรสเผ็ดไปจนถึงทาโกะยากิแสนอร่อย ตอบสนองทุกรสนิยมของคุณ",
    "loadingTitle5": "ความสงบในธรรมชาติ",
    "loadingDescription5": "หลีกหนีความวุ่นวายในเมืองด้วยการเดินเล่นผ่านสวนไผ่อันมีเสน่ห์ สัมผัสความเงียบสงบและความงามเหนือกาลเวลาของเมืองประวัติศาสตร์แห่งนี้",
    "loadingTitle6": "ปรากฏการณ์ดอกซากุระ",
    "loadingDescription6": "วางแผนการมาเยือนของคุณในช่วงฤดูดอกซากุระบานเพื่อชมทิวทัศน์อันเป็นเอกลักษณ์ของญี่ปุ่นที่อาบไปด้วยสีชมพู เข้าร่วมกับคนท้องถิ่นในการเฉลิมฉลองฮานามิเพื่อประสบการณ์ที่มหัศจรรย์อย่างแท้จริง",
    "fromBwToOtaPaymentError": "บางอย่างผิดพลาด. โปรดกดปุ่ม 'ถัดไป' หรือเลือกวิธีการชำระเงินอื่นเพื่อลองอีกครั้ง",
    "fromBwToOtaRestoreDataError": "บางอย่างผิดพลาด. โปรดป้อนข้อมูลของคุณอีกครั้งแล้วกดปุ่ม 'ถัดไป' อีกครั้ง",
    "searchHotels": "ค้นหาโรงแรม",
    "aiRecommendations": "คำแนะนำด้านเอไอ",
    "recommendationsForU": "คำแนะนำสำหรับคุณ",
    "interestedIn": "ค้นพบคำแนะนำทางเลือก",
    "home": "บ้าน",
    "showingCountAvailable": "กำลังแสดง {count} ที่มีอยู่จากคุณสมบัติทั้งหมด {total}",
    "standardTravelAgency": "สภาพธุรกิจ",
    "termsOfUse": "ระยะเวลาของการใช้งาน",
    "privacyPolicy": "นโยบายความเป็นส่วนตัว",
    "companyProfile": "ประวัติบริษัท",
    "bookingSupport": "การสนับสนุนการจอง",
    "search": "ค้นหาโรงแรม",
    "checkAvailability": "ตรวจสอบห้องว่าง",
    "avgPricePerNight": "ราคาอ้างอิงต่อคืน",
    "adults": "{n} ผู้ใหญ่ | {n} ผู้ใหญ่ | {n} ผู้ใหญ่",
    "GDPR": "นโยบายความเป็นส่วนตัวของ GDPR",
    "homepage": {
      "recommend1": "ค้นหาโรงแรมราคาประหยัดในโตเกียวสำหรับการเข้าพักช่วงสุดสัปดาห์ให้ฉัน",
      "recommend2": "โปรดช่วยฉันหาโรงแรมรีสอร์ทในโอกินาว่าสำหรับสามคน",
      "recommend3": "สำรวจเรียวกังในเกียวโตสำหรับวันเกิดภรรยาของฉัน",
      "recommend4": "เดือนหน้าฉันจะเดินทางไปทำธุรกิจที่โกเบ มีโรงแรมที่พักเดี่ยวแนะนำบ้างมั้ยคะ?",
      "placeholder": "เช่น. ฉันต้องการจองห้องพักสำหรับสองคนเป็นเวลาสามคืนที่โรงแรมใกล้ชินจูกุ"
    },
    "bookingConfirmation": "การยืนยันการจอง",
    "confirmToOtaTitle": "กระบวนการยืนยันการจอง",
    "confirmToOtaDescription": "คุณจะถูกนำไปยังเว็บไซต์ของตัวแทนการท่องเที่ยว ({0}) การจองของคุณจะเสร็จสมบูรณ์โดยชำระเงินล่วงหน้าในหน้าถัดไป",
    "confirmToOtaDescriptionInGuestForm": "คุณจะถูกนำไปยังเว็บไซต์ของตัวแทนการท่องเที่ยว ({0}) การจองของคุณจะเสร็จสมบูรณ์โดยชำระเงินล่วงหน้าในหน้าถัดไป",
    "confirmToOtaDescriptionInGuestFormOnsiteCC": "คุณจะถูกนำไปยังเว็บไซต์ของตัวแทนการท่องเที่ยว ({0}) การจองของคุณจะเสร็จสมบูรณ์โดยการลงทะเบียนบัตรเครดิตในหน้าถัดไป",
    "aiSearchNoResult": "ขณะนี้ AI ของเราอยู่ในกระบวนการเรียนรู้และไม่สามารถสร้างคำแนะนำที่เกี่ยวข้องตามข้อมูลที่คุณป้อนได้ โปรดให้รายละเอียดเพิ่มเติม เช่น ที่ตั้ง จำนวนคน คำอธิบายสิ่งอำนวยความสะดวก ฯลฯ นอกจากนี้เรายังมีคำแนะนำต่อไปนี้ที่คุณอาจพบว่าน่าสนใจ"
  },
  "otaRoomsResult": {
    "noRoomsAvailable": "ขออภัย ไม่มีห้องว่างที่ตรงกับความต้องการของคุณ",
    "noRoomsAvailableDesc": "โปรดเลือกวันที่อื่น หรือเปลี่ยนจำนวนผู้เข้าพักเพื่อดูว่าเรามีห้องว่างสำหรับคุณหรือไม่",
    "location": "ที่ตั้ง",
    "showAllPhotos": "แสดงภาพถ่ายทั้งหมด"
  },
  "otaRoomsMap": {
    "title": "แผนที่",
    "seeMap": "ดูแผนที่"
  },
  "paymentMethod": {
    "paymentKRWCreditCardFooterTitle": "Online payment is processed by:",
    "paymentKRWCreditCardFooterLine1": "tripla Co., Ltd. (Korea branch)",
    "paymentKRWCreditCardFooterLine1CEO": "CEO: Kazuhisa Takahashi",
    "paymentKRWCreditCardFooterLine2": "Business registration number: 538-84-00072",
    "paymentKRWCreditCardFooterLine3": "3F Concordian Building, 76 Saemunan-ro, Jung-gu, Seoul, South Korea (P. 03185)",
    "paymentKRWCreditCardFooterLine4": "support_ko{'@'}tripla.io",
    "paymentKRWCreditCardFooterLine4Link": "mailto:support_ko{'@'}tripla.io",
    "paypalCheckbox1": "โปรดใช้บัตรเครดิตของผู้ที่ทำการจอง หากชื่อผู้ถือบัตรไม่ตรงกับชื่อการจอง การจองอาจถูกยกเลิกโดยไม่ต้องแจ้งให้ทราบล่วงหน้า",
    "paypalCheckbox2": "ไม่สามารถใช้งานได้หากผู้ออกบัตรเครดิตไม่รองรับ 3D Secure",
    "paypalCheckbox3": "ข้อมูลส่วนบุคคลต่อไปนี้ที่บริษัทของเราเก็บรวบรวมจะถูกมอบให้กับผู้ออกบัตรที่คุณใช้ในการตรวจจับและป้องกันการใช้งานโดยไม่ได้รับอนุญาต ชื่อ หมายเลขโทรศัพท์ ที่อยู่อีเมล สภาพแวดล้อมอินเทอร์เน็ต ฯลฯ",
    "paypalCheckbox4": "หากผู้ออกบัตรที่คุณใช้อยู่ในต่างประเทศ ข้อมูลนี้อาจถูกโอนไปยังประเทศที่ผู้ออกบัตรอยู่",
    "paypalCheckbox5": "ผู้เยาว์จะได้รับอนุญาตให้ใช้บริการนี้โดยได้รับความยินยอมจากพ่อแม่หรือผู้ปกครอง",
    "pre_paid": "การชำระเงินด้วยบัตรเครดิต",
    "credit_card": "ชำระเงินล่วงหน้า",
    "payments": {
      "credit_card": "การชำระเงินด้วยบัตรเครดิต"
    },
    "deferred_payment": "การชำระเงินเลื่อนออกไป",
    "on_site": "บนเว็บไซต์",
    "on_site_credit_card_required": "บนเว็บไซต์",
    "type": "ประเภทการชำระเงิน",
    "twd_credit_card": "ชำระเงินเต็มจำนวนด้วยบัตรเครดิต",
    "twd_credit_card_deposit": "ฝากเงินด้วยบัตรเครดิต",
    "bank_transfer": "โอนเงินผ่านธนาคาร",
    "paymentPromotion": "โปรโมชั่นการชำระเงิน",
    "paymentPromotionTooltip": "โปรโมชั่นอาจมีการเปลี่ยนแปลงหรือปิดโดยไม่มีการเตือนล่วงหน้า",
    "undefined": ""
  },
  "pointHistory": {
    "availableAt": "สามารถดูได้ที่",
    "cancellation": "การยกเลิก",
    "dateColumn": "วันที่",
    "descriptionColumn": "คำอธิบาย",
    "failToLoadErrorMessage": "ฉันเสียใจ. เราไม่สามารถโหลดประวัติคะแนนของคุณในขณะนี้",
    "noData": "ตอนนี้คุณไม่มีประวัติแต้มเลย",
    "pointExpirationColumn": "จุดหมดอายุ",
    "pointHistory": "ประวัติจุด",
    "pointsColumn": "จำนวนคะแนน",
    "pointUse": "การใช้จุด",
    "stay": "อยู่",
    "viewMore": "ดูเพิ่มเติม",
    "exchangeDigico": "{point} แลกเปลี่ยน",
    "exchangeDigicoDesc": "กรุณากรอกจำนวน {point} ที่คุณต้องการแลกเปลี่ยนเป็นคะแนน Digico คะแนน Digico สามารถใช้แลกเปลี่ยน {point} ของคุณไปยังโปรแกรมคะแนนอื่น ๆ รวมถึงคะแนน Amazon, Apple, Google กระบวนการนี้ไม่สามารถย้อนกลับได้ และคะแนน Digico จะมีอายุเพียง 180 วันหลังจากการแลกเปลี่ยน บริการนี้สามารถเข้าถึงได้ภายในประเทศญี่ปุ่นเท่านั้น สิ่งอำนวยความสะดวกไม่สามารถตอบคำถามใด ๆ เกี่ยวกับคะแนน Digico ได้เมื่อมีการแลกเปลี่ยนแล้ว สำหรับข้อมูลเพิ่มเติมเกี่ยวกับคะแนน Digico โปรดดูที่ {link}",
    "exchangeDigicoLink": "ที่นี่",
    "exchangeDigicoLog": "การแลกเปลี่ยนคะแนน Digico",
    "exchangeAvaliablePoints": "มีทั้งหมด {point}",
    "exchangeBtn": "แลกเปลี่ยน",
    "exchangeHistory": "{point} แลกเปลี่ยน",
    "exchangedAmount": "จำนวนการแลกเปลี่ยน",
    "exchangedDigicoCode": "รหัส",
    "exchangeConfirm": "กระบวนการนี้ไม่สามารถย้อนกลับได้ คุณแน่ใจหรือไม่ว่าต้องการยืนยันการแลกเปลี่ยน {point} ของคุณ?",
    "exchangeConfirmBtn": "ดำเนินการต่อ",
    "digicoPointUrl": "URL การแลกเปลี่ยนคะแนน Digico:",
    "invalidAmount": "กรุณากรอกจำนวนคะแนนที่ถูกต้อง",
    "totalBalance": "ทั้งหมด",
    "currentPoints": "คะแนนที่มีอยู่",
    "futurePoints": "คะแนนในอนาคต"
  },
  "pointSpending": {
    "balance": "คะแนนคงเหลือคงเหลือ",
    "errors": {
      "incorrectAmount": "คุณสามารถใช้แต้มเพิ่มขึ้นทีละ {n}",
      "moreThanPrice": "คุณไม่สามารถใช้คะแนนเกินราคารวมได้",
      "upToBalance": "คุณไม่สามารถใช้แต้มมากกว่ายอดคงเหลือของคุณได้",
      "pointUsageError": "ไม่อนุญาตให้ดำเนินการชำระเงินด้วยบัตรเครดิตเมื่อยอดการชำระเงินทั้งหมดของคุณเป็น 0\nหากต้องการเปิดใช้งานการชำระเงินออนไลน์ คุณสามารถลดคะแนนการใช้จ่ายได้ในขั้นตอนก่อนหน้า"
    },
    "minimumAmount": "จำนวนคะแนนการใช้จ่ายขั้นต่ำต้องเป็น {0} คะแนนขึ้นไป",
    "points": "คะแนน",
    "saveForLater": "บันทึกคะแนนของฉันไว้ใช้ในภายหลัง",
    "updateRule": "คุณไม่สามารถใช้คะแนนที่ใช้ในการจองเดิมได้ เราจะคืนคะแนนสำหรับการจองเดิมหลังจากที่การจองได้รับการอัปเดตแล้ว",
    "useAll": "แลกคะแนนเต็มของฉัน",
    "useAmount": "แลกเป็นจำนวนเงินที่กำหนด",
    "title": "คะแนน"
  },
  "points": {
    "naming": {
      "default": "จุด",
      "history": "{จุด} ประวัติ",
      "available": "ใช้ได้ {จุด}",
      "future": "อนาคต {จุด}",
      "expired": "หมดอายุแล้ว {จุด}",
      "gained": "ได้รับ {point}",
      "spent": "ใช้จ่ายไป {point}",
      "save": "บันทึก {point} ของฉันไว้ใช้ในภายหลัง",
      "redeem": "แลกยอดคงเหลือ {point} เต็มของฉัน",
      "redeem2": "แลกรับคะแนนสูงสุด",
      "refunded": "คืนเงิน {คะแนน}",
      "miniSpending": "ยอดใช้จ่ายขั้นต่ำ {point} ต้องเป็น {number} หรือมากกว่า",
      "useIncrements": "คุณสามารถใช้ {point} โดยเพิ่มทีละ {number}",
      "discount": "ส่วนลด {point}",
      "earn": "รับ {number} {point} เมื่อจองห้องพักนี้",
      "checkOut": "{point} จะถูกเพิ่ม 3 วันหลังจากวันเช็คเอาต์",
      "recalculateOnCheckoutDate": "จำนวน {point} ที่แสดงเป็นการประมาณการ มูลค่าที่แน่นอนจะถูกคำนวณใหม่ในวันที่ชำระเงิน",
      "usePoint": "ด้วย {จุด}",
      "empty": "คุณไม่มีประวัติ {point} ใด ๆ ในขณะนี้",
      "total": "ทั้งหมด",
      "availableBalance": "ยอดคงเหลือ {point} ที่มีอยู่",
      "redeemRemark": "แลกเป็นจำนวนเงินที่กำหนด",
      "cannotUse": "คุณไม่สามารถใช้ {point} ที่ใช้สำหรับการจองเดิมได้ เราจะคืนเงิน {point} สำหรับการจองเดิมหลังจากอัปเดตการจองแล้ว"
    },
    "getRegisterPoint": "รับ {amount} {points_name} เมื่อสมัครเป็นสมาชิก"
  },
  "progressBar": {
    "completeLottery": "รายการลอตเตอรีที่สมบูรณ์",
    "completeLotterySubtitle": "คุณได้กรอกรายการลอตเตอรีแล้ว",
    "confirmationSubtitle": "ห้องของคุณถูกจองไว้แล้ว",
    "confirmationSubtitleOTA": "ห้องของคุณถูกจองไว้แล้ว",
    "confirmationTitle": "การจองเสร็จสมบูรณ์",
    "confirmationTitleOTA": "การยืนยัน",
    "guestSubtitle": "กรอกรายละเอียดของคุณ",
    "guestTitle": "รายละเอียดผู้เข้าพัก",
    "paymentSubtitle": "เลือกประเภทการชำระเงิน",
    "paymentTitle": "การชำระเงิน"
  },
  "reservation": {
    "cancelled": "ยกเลิก",
    "completed": "สมบูรณ์",
    "upcoming": "ที่กำลังจะมีขึ้น",
    "lottery": "หวย",
    "filtersLabel": "กรอง",
    "filters": {
      "all": "ทั้งหมด",
      "onSiteChecked": "ชำระเงินนอกสถานที่",
      "on_site_credit_card_required": "ชำระเงินนอกสถานที่",
      "otaPrePaidChecked": "ชำระเงินด้วยบัตรเครดิตภายใน {0}",
      "bookingWidgetPrePaidChecked": "ชำระเงินออนไลน์ด้วยบัตรเครดิต",
      "deferredPaymentChecked": "การชำระเงินเลื่อนออกไป"
    },
    "reservedChannel": {
      "all": "ทั้งหมด",
      "title": "ช่องที่จองไว้",
      "officialWebsite": "เว็บไซต์อย่างเป็นทางการ (ชำระเงิน ณ สถานที่)",
      "wbf": "แพ็คเกจไดนามิก",
      "tripla": "เว็บไซต์จองการเดินทาง (ชำระด้วยบัตรเครดิต)"
    },
    "signInUpReminder": "ลงชื่อเข้าใช้ / เพื่อรับสิทธิประโยชน์การเป็นสมาชิก"
  },
  "reservationAction": {
    "contactTriplaSupport": "ติดต่อฝ่ายสนับสนุน tripla",
    "applyGOTO": "ใช้รหัสคูปอง",
    "bookingCancel": "ยกเลิกการจองของคุณ",
    "bookingUpdate": "อัพเดตรายละเอียดผู้เข้าพัก",
    "corporateSignInRequired": "ต้องลงชื่อเข้าใช้บริษัทเพื่ออัปเดตแผนการเดินทางของคุณ",
    "differentFacilityWarning": "หากต้องการต่ออายุการจองของคุณ โปรดเข้าสู่ระบบผ่านสิ่งอำนวยความสะดวกที่คุณจองไว้",
    "everythingCorrectSubtitle": "คุณสามารถอัปเดตข้อมูลของคุณหรือยกเลิกการจองด้านล่างนี้",
    "everythingCorrectTitle": "จัดการการจอง",
    "invoiceDownload": "พิมพ์รายละเอียด",
    "madeMistakeHelp": "เราหวังว่าจะได้ให้บริการคุณอีกครั้งในเร็ว ๆ นี้",
    "madeMistakeTitle": "การจองของคุณถูกยกเลิกแล้ว",
    "memberSignInRequired": "จำเป็นต้องลงชื่อเข้าใช้สมาชิกเพื่ออัพเดตการจอง",
    "pleaseCancelAll": "​หากท่านต้องการยกเลิกห้องพักทั้งหมดกรุณายกเลิกการจอง",
    "payByCreditCard": "จ่ายด้วยบัตรเครดิต",
    "reductionOfNights": "ลดจำนวนคืน",
    "reductionOfRooms": "การลดจำนวนห้องพัก",
    "selectRoomsToCancel": "กรุณาเลือกห้องที่คุณต้องการยกเลิก",
    "signOutRequired": "กรุณาออกจากระบบเพื่ออัพเดตการจอง",
    "updateExpired": "ไม่สามารถอัปเดตการจองนี้ได้อีกต่อไปเนื่องจากเลยกำหนดเวลาไปแล้ว",
    "updateItinerary": "อัปเดตแผนการเดินทางของคุณ",
    "differentAccountWarning": "โปรดลงชื่อเข้าใช้ด้วยบัญชีที่ถูกต้องเพื่ออัปเดตการจอง",
    "differentOrgWarning": "โปรดลงชื่อเข้าใช้ด้วยบริษัทที่ถูกต้องเพื่ออัปเดตการจอง",
    "coupon": {
      "signOutRequired": "กรุณาออกจากระบบเพื่อใช้คูปอง",
      "memberSignInRequired": "จำเป็นต้องลงชื่อเข้าใช้สมาชิกจึงจะใช้คูปองได้",
      "corporateSignInRequired": "ต้องลงชื่อเข้าใช้บริษัทจึงจะใช้คูปองได้",
      "differentFacilityWarning": "หากต้องการใช้คูปอง โปรดเข้าสู่ระบบผ่านสิ่งอำนวยความสะดวกที่คุณจองไว้",
      "differentAccountWarning": "โปรดลงชื่อเข้าใช้ด้วยบัญชีที่ถูกต้องเพื่อใช้คูปอง",
      "differentOrgWarning": "โปรดลงชื่อเข้าใช้ด้วยบริษัทที่ถูกต้องเพื่อใช้คูปอง"
    },
    "notEnoughStays": "ระยะเวลาการเข้าพักที่เลือกควรเท่ากับหรือมากกว่าระยะเวลาการเข้าพักขั้นต่ำของการจองนี้ ({0} คืน)\n",
    "viewMyReservations": "ดูการจองของฉัน",
    "madeMistakeHelp2": "เรารอคอยที่จะให้บริการคุณอีกครั้งในเร็ว ๆ นี้",
    "madeMistakeTitle2": "การจองถูกยกเลิก"
  },
  "reservationCancellation": {
    "roomN": "ห้อง {0}",
    "cancellationFees": "ค่าธรรมเนียมการยกเลิก",
    "cancellationConfirm2": "คุณแน่ใจหรือว่าจะยกเลิกการจองของคุณ?",
    "reason2": "กรุณาแบ่งปันเหตุผลในการยกเลิกของคุณ",
    "reasonTripCancelled2": "การเปลี่ยนแปลงแผนการเดินทาง",
    "reasonRoomCheaper2": "พบตัวเลือกที่ถูกกว่าที่อื่น",
    "reasonNbTrvellers2": "ปรับจำนวนผู้เดินทาง",
    "reasonDifferentHotel2": "เลือกใช้โรงแรมอื่น",
    "reasonOther2": "อื่น ๆ (โปรดระบุ)",
    "cancel": "ยกเลิกการจอง",
    "cancellationConfirm": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการจองของคุณ?",
    "cancellationFee": "ค่าธรรมเนียมการยกเลิก",
    "cancellationOnDay": "การยกเลิกในวันนั้นไม่สามารถทำได้ทางออนไลน์",
    "cancellationPolicy": "นโยบายการยกเลิก",
    "cancellationTitle": "ยกเลิกการจองของคุณ",
    "contactByChatPhone": "กรุณาติดต่อเราทางโทรศัพท์",
    "contactByPhone": "กรุณาติดต่อเราทางโทรศัพท์",
    "contactPhone": "หมายเลขโทรศัพท์ {0}",
    "keep": "จองไว้ครับ",
    "nonRefundable": "ไม่สามารถขอคืนเงินได้",
    "reason": "โปรดระบุเหตุผลที่คุณต้องการยกเลิก",
    "reasonDifferentHotel": "ฉันพบโรงแรมอื่น",
    "reasonNbTrvellers": "จำนวนนักเดินทางเปลี่ยนไป",
    "reasonOther": "อื่น",
    "reasonOtherHelp": "หากเป็นอย่างอื่น โปรดระบุรายละเอียดให้เราด้วย",
    "reasonRoomCheaper": "ห้องถูกกว่าที่อื่น",
    "reasonTripCancelled": "การเดินทางของฉันถูกยกเลิก",
    "roomNbCancelFee": "ค่าธรรมเนียมการยกเลิกห้อง {0}",
    "totalCancelFee": "ค่าธรรมเนียมการยกเลิกทั้งหมด"
  },
  "reservationConfirmation": {
    "bookingConfirmed": "การจองของคุณได้รับการยืนยันแล้ว",
    "cancelledSuccessfully": "ยกเลิกการจองเรียบร้อยแล้ว",
    "totalStays": "ระยะเวลาการเข้าพักทั้งหมด",
    "contactProperty": "ติดต่อที่พัก",
    "yourRoomDetails": "รายละเอียดห้องพักของคุณ",
    "phone": "โทรศัพท์",
    "email": "อีเมล",
    "website": "เว็บไซต์",
    "cancellationFreeUntil": "ยกเลิกฟรีจนถึง {0}",
    "cannotChangePlan": "คุณไม่สามารถเปลี่ยนแปลงแผนการจองเดิมได้",
    "confirmationEmail": "เราได้ส่งอีเมลยืนยันไปที่ {0} แล้ว",
    "extra": {
      "answer": "คำตอบ",
      "dateTime": "วันเวลา",
      "extrasPrice": "บริการพิเศษ",
      "items": "รายการ",
      "notes": "หมายเหตุ",
      "price": "ราคา",
      "priceIncludeTax": "ราคา (รวมภาษี)",
      "question": "คำถาม",
      "roomPrice": "ราคาห้องพักรวม"
    },
    "extrasSubtotal": "ยอดรวมของส่วนเกิน",
    "nonRefundableReminder": "การจองนี้ไม่สามารถคืนเงินได้ โปรดทราบว่าจะไม่มีการคืนเงินใด ๆ ทั้งสิ้นสำหรับการยกเลิกของคุณ",
    "originalReservationPlans": "แผนการจองเดิม",
    "reservationDetail": "รายละเอียดการจอง",
    "selectNewItinerary": "โปรดเลือกแผนการเดินทางใหม่และจำนวนคนของคุณ",
    "updateBooking": "อัพเดตการจองของคุณ",
    "cannotUpdateTooltip": "เนื่องจากเนื้อหาการจองหรือราคาได้รับการแก้ไขโดยเจ้าหน้าที่อำนวยความสะดวกของเราก่อนหน้านี้ตามข้อตกลงของคุณ คุณจึงไม่สามารถอัปเดต/ยกเลิกการจองได้"
  },
  "reservationDetail": {
    "bookingDetails": "รายละเอียดการจอง",
    "adult": "{n} ผู้ใหญ่ | {n} ผู้ใหญ่ | {n} ผู้ใหญ่",
    "accommodationTax": "ภาษีที่พัก",
    "checkIn": "วันที่เช็คอิน",
    "checkOut": "วันที่เช็คเอาท์",
    "child": "{n} เด็ก | {n} เด็ก | {n} เด็ก ๆ",
    "dayUse": "วันใช้",
    "details": "",
    "detailsTitle": "รายละเอียดการจอง",
    "discount": "การลดราคา",
    "discounts": "ส่วนลด",
    "discountedPrice": "ส่วนลดคะแนน",
    "guest": "{n} แขก | {n} แขก | {n} แขก",
    "guests": "แขก",
    "hotel": "โรงแรม",
    "maxGuests": "แขกแม็กซ์",
    "night": "{n} กลางคืน | {n} กลางคืน | {n} คืน",
    "nights": "คืน",
    "numberOfGuest": "ห้องพัก",
    "numberOfNights": "คืน",
    "numberOfPeople": "จำนวนคน",
    "numberOfAdults": "{n} ผู้ใหญ่ | {n} ผู้ใหญ่ | {n} ผู้ใหญ่",
    "numberOfChildren": "{n} เด็ก | {n} เด็ก | {n} เด็ก ๆ",
    "originalPrice": "จำนวนเงินที่ชำระทั้งหมด",
    "pointDiscount": "ส่วนลดคะแนน",
    "pointInstruction": "คะแนนจะถูกเพิ่ม 3 วันหลังจากวันเช็คเอาต์",
    "pointReward": "รับ {0} คะแนนเมื่อจองห้องพักนี้",
    "priceBreakDown": "รายละเอียดราคา",
    "priceDetails": "รายละเอียดราคา",
    "reservationDetails": "รายละเอียดการจอง",
    "reservationNumber": "หมายเลขการจอง",
    "roomDetails": "รายละเอียดห้องพัก",
    "roomNb": "ห้อง {0}",
    "roomPlan": "ชื่อแผน",
    "roomRate": "อัตราค่าห้องพัก",
    "roomType": "ประเภทห้อง",
    "roomWithChildren": "{nRoom} ห้องสำหรับผู้ใหญ่ {nAdult} และเด็ก {nChild} คน",
    "roomWithoutChildren": "{nRoom} ห้องสำหรับผู้ใหญ่ {nAdult}",
    "searchNecessary": "* หมายเลขการจองนี้จำเป็นเมื่อคุณอัปเดต/ยกเลิกการจองหรือสอบถามเกี่ยวกับการจองกับเรา",
    "serviceFee": "ค่าบริการ",
    "selected": "คุณเลือกแล้ว",
    "subTotal": "ผลรวมย่อย",
    "subTotalWithoutTax": "ยอดรวม (ไม่รวมภาษี)",
    "tax": "ภาษี",
    "taxIncluded": "รวมภาษี",
    "total": "จำนวนเงินที่ชำระ (รวมภาษี)",
    "totalAmount": "จำนวนเงินทั้งหมด",
    "totalLengthOfStay": "ระยะเวลาการเข้าพักทั้งหมด",
    "totalPriceWithTax": "ราคารวมภาษี",
    "depositAmount": "จำนวนเงินฝาก ({0}%)",
    "remainAmount": "ยอดเงินคงเหลือ",
    "withTax": "พร้อมภาษี",
    "depositNecessary": "คุณต้องชำระเงินมัดจำ {0}% ผ่านระบบออนไลน์ของเราเพื่อสรุปการยืนยันการจอง"
  },
  "reservationPayment": {
    "creditCardPayment": "ชำระเงินล่วงหน้าด้วยบัตรเครดิต",
    "onSitePayment": "ชำระเงินที่โรงแรม (ไม่ต้องชำระเงินล่วงหน้า)",
    "paymentMethod": "วิธีการชำระเงิน",
    "pendingPage": {
      "launchButton": "การรักษาความปลอดภัยแบบ 3 มิติ",
      "text01": "คลิกปุ่มเพื่อดูหน้าจอการป้อนรหัสผ่าน 3D Secure",
      "text02": "หากไม่กรอกรหัสผ่านภายใน 15 นาที ขั้นตอนการจองจะถูกยกเลิก",
      "text03": "การจองจะไม่สมบูรณ์จนกว่าคุณจะกรอกรหัสผ่าน ดังนั้นอาจมีการสำรองที่นั่งไว้",
      "text04": "tripla จะจัดการการชำระเงินด้วยบัตรเครดิต ดังนั้น \"tripla\" จะแสดงในชื่อร้านค้า 3D Secure",
      "timeOutError01": "เกิดข้อผิดพลาดการหมดเวลา",
      "timeOutError02": "การชำระเงินไม่ครบตามหลักสูตรปกติ อย่างไรก็ตาม หากมีการเรียกเก็บเงินจากบัตรเครดิต เราจะคืนเงินให้ กรุณายืนยันรายละเอียดระยะเวลาการชำระเงินและการคืนเงินกับบริษัทบัตรเครดิต",
      "authError01": "กระบวนการชำระเงินล้มเหลว",
      "error01": "เกิดข้อผิดพลาดระหว่างขั้นตอนการจอง",
      "error02": "การชำระเงินไม่ครบตามหลักสูตรปกติ อย่างไรก็ตาม หากมีการเรียกเก็บเงินจากบัตรเครดิต เราจะคืนเงินให้ กรุณายืนยันรายละเอียดระยะเวลาการชำระเงินและการคืนเงินกับบริษัทบัตรเครดิต",
      "error03": "คลิกปุ่ม \"ย้อนกลับ\" และจองอีกครั้งจากหน้าจอผลการค้นหา"
    },
    "planError": "ขออภัย แผนที่เลือกมีประเภทการชำระเงินที่ขัดแย้งกัน กรุณาลองอีกครั้ง.",
    "selectCreditCards": "เลือกบัตรเครดิต",
    "sessionErrorMessage": "บางอย่างผิดพลาด. โปรดกลับไปที่หน้ารายละเอียดแขกแล้วลองอีกครั้ง",
    "sessionErrorButtonText": "กลับไปที่รายละเอียดแขก",
    "gmoCreditCardMaintenanceWarning": "ตั้งแต่เวลา 4:00 น. ถึง 6:00 น. (เวลาญี่ปุ่น) วันที่ 18 พฤศจิกายน 2024 แบรนด์การ์ดจะไม่สามารถใช้งานได้เนื่องจากการบำรุงรักษาระบบ โปรดเลือกวิธีการชำระเงินอื่นหรือรอจนกว่าการบำรุงรักษาจะเสร็จสิ้น",
    "gmoOnSiteCreditCardRequiredMaintenanceWarning": "ตั้งแต่เวลา 4:00 ถึง 6:00 น. (ตามเวลามาตรฐานญี่ปุ่น) ของวันที่ 18 พฤศจิกายน 2024 การลงทะเบียนบัตรจะไม่สามารถใช้ได้เนื่องจากการบำรุงรักษาระบบ โปรดเลือกวิธีการชำระเงินอื่นหรือรอจนกว่าการบำรุงรักษาจะเสร็จสิ้น",
    "gmoMcpMaintenanceWarning": "ตั้งแต่เวลา 04.00 น. ถึง 06.00 น. (เวลามาตรฐานญี่ปุ่น) วันที่ 18 พฤศจิกายน 2567 ไม่สามารถใช้ VISA และ Mastercard ได้เนื่องจากการบำรุงรักษาระบบ โปรดเลือกวิธีการชำระเงินอื่นหรือรอจนกว่าการบำรุงรักษาจะเสร็จสิ้น"
  },
  "reservationReceipt": {
    "customerName": "ชื่อลูกค้าในการรับสินค้า",
    "deferredWithoutDownload": "จะไม่ออกใบเสร็จรับเงินสำหรับการชำระเงินที่เลื่อนออกไป",
    "download": "ดาวน์โหลดใบเสร็จรับเงิน",
    "downloadOnCheckout": "สามารถดาวน์โหลดใบเสร็จของคุณได้ในวันที่เช็คเอาต์และเมื่อใดก็ได้หลังจากนั้น",
    "downloadAtSpecificTime": "สามารถดาวน์โหลดใบเสร็จของคุณได้ในวันที่ {date} เวลา {time} และเมื่อใดก็ได้หลังจากนั้น",
    "onSite": "สำหรับการชำระเงินในสถานที่ จะมีการออกใบเสร็จรับเงินที่แผนกต้อนรับของโรงแรม"
  },
  "searchBar": {
    "addRoom": "เพิ่มอีกห้อง",
    "adult": "{n} ผู้ใหญ่ | {n} ผู้ใหญ่ | {n} ผู้ใหญ่",
    "adults": "ผู้ใหญ่",
    "age": "อายุ",
    "agree": "เห็นด้วย",
    "bed": "เตียง",
    "checkIn": "เช็คอิน",
    "checkInAndOut": "วันที่เช็คอิน-เช็คเอาท์",
    "checkOut": "เช็คเอาท์",
    "child": "{n} เด็ก | {n} เด็ก | {n} เด็ก ๆ",
    "childN": "เด็ก {n}",
    "children": "เด็ก",
    "childrenAgeAtCheckout": "อายุของเด็กเมื่อชำระเงิน",
    "childrenAgeInfant": "อายุ {จาก}-{ถึง}",
    "childrenAgePrimary": {
      "primary": "อายุ {จาก}-{ถึง}",
      "primaryBottom": "อายุ {จาก}-{ถึง}",
      "primaryTop": "อายุ {จาก}-{ถึง}"
    },
    "childrenAges": "อายุของเด็กที่คุณเดินทางด้วย?",
    "childrenAgeSharedBed": "อายุ {จาก}-{ถึง}",
    "childrenMealBedding": {
      "meal_no": "ไม่มีอาหารและเตียง",
      "meal_no_bedding": "เตียงเท่านั้น",
      "meal_same_as_adult_bedding": "อาหารและเตียง",
      "meal_yes": "มื้ออาหารเท่านั้น",
      "meal_yes_bedding": "อาหารและเตียง"
    },
    "cookieConsent": "เว็บไซต์นี้ใช้คุกกี้เพื่อปรับปรุงประสบการณ์ผู้ใช้ของคุณ การใช้เว็บไซต์นี้ต่อไปแสดงว่าคุณยอมรับความยินยอมในการใช้คุกกี้ของเรา สำหรับข้อมูลเพิ่มเติม โปรดตรวจสอบ {0}",
    "privacyPolicy": "นโยบายส่วนบุคคล",
    "perRoomGuests": "จำนวนผู้เข้าพักต่อห้อง",
    "dates": "วันที่",
    "dayUse": "จองเพื่อใช้เฉพาะวันเท่านั้น",
    "defaultArea": "สถานที่ใดก็ได้",
    "edit": "แก้ไข",
    "filter": "กรอง",
    "guests": "แขก",
    "hotel": "โรงแรม",
    "maxChildrenWarning": "จำนวนเด็กสูงสุดต่อผู้ใหญ่หนึ่งคนคือ {n}",
    "pleaseSelectHotel": "กรุณาเลือกโรงแรม",
    "room": "{n} ห้อง | {n} ห้อง | {n} ห้อง",
    "rooms": "ห้องพัก",
    "search": "ค้นหา",
    "showOnlyAvailable": "แสดงได้เฉพาะเท่านั้น",
    "sort": "เรียงลำดับ",
    "sortOption": {
      "price_high_to_low": "ราคาสูงไปต่ำ",
      "price_low_to_high": "ราคาต่ำไปสูง",
      "recommended": "ที่แนะนำ",
      "asc": "ราคาต่ำไปสูง",
      "desc": "ราคาสูงไปต่ำ"
    },
    "to": "ถึง",
    "undated": "ไม่แน่ใจวันที่",
    "nights": "คืน",
    "yearOld": "{n} ปี | {n} ปี | {n} ปี",
    "enableWBFSearch": "เปิดใช้งานการค้นหาโรงแรม + เที่ยวบินแพ็คเกจ",
    "stayFlightTab": "อยู่+บิน",
    "stayTab": "อยู่",
    "processInJapTxt": "ขั้นตอนการจองให้บริการเป็นภาษาญี่ปุ่นเท่านั้น",
    "processMaxTxt": "ขั้นตอนการสำรองห้องพักเป็นภาษาญี่ปุ่นเท่านั้น โดยจะมีห้องพักได้สูงสุด 5 ห้องสำหรับการจอง Stay+Flight",
    "undatedWarning": "ไม่สามารถค้นหาโดยไม่ระบุวันเช็คอินได้หากจำนวนคนในแต่ละห้องไม่เท่ากัน",
    "undatedWarning2": "ไม่สามารถใช้ได้กับการค้นหาด้วย \"เที่ยวบิน + ห้อง\"",
    "flightAndRoomsWarning": "ไม่สามารถใช้ได้กับการค้นหาด้วย \"Date undecide\""
  },
  "searchReservation": {
    "enterDetail": "กรอกรายละเอียดของคุณด้านล่าง",
    "enterReservationNumber": "กรอกหมายเลขการจองของคุณ",
    "manageBooking": "จัดการการจองของคุณ",
    "searchFailed": "เราต้องการข้อมูลต่อไปนี้เพื่อดำเนินการต่อ ขออภัย เราไม่รู้จักอีเมลหรือหมายเลขการจอง กรุณาลองอีกครั้ง.",
    "confirmReservation": "ยืนยันการจอง",
    "confirmReservationDesc": "เนื่องจากการจองนี้ชำระเงินโดย {ota} คุณจะถูกเปลี่ยนเส้นทางไปยัง {ota} ใน {time} วินาที"
  },
  "searchResultByPlan": {
    "hide": "ซ่อน",
    "planContent": "คำอธิบายแผน",
    "plans": "แผน",
    "rooms": "ห้องพัก",
    "showPlan": "แสดงรายละเอียดแผน",
    "moreDetail": "รายละเอียดเพิ่มเติม"
  },
  "snsLogin": {
    "loginWith": "เข้าสู่ระบบด้วย {0}",
    "enterPassword": "กรอกรหัสผ่านบัญชีสมาชิกของคุณเพื่อดำเนินการต่อ",
    "existingPrompt": "ดูเหมือนว่าคุณมีบัญชีอยู่แล้ว คุณต้องการเชื่อมโยงบัญชีของคุณกับ {sns} หรือไม่? คุณจะสามารถเข้าสู่ระบบได้ด้วย {sns} และอีเมล/รหัสผ่านปกติของคุณ",
    "signUpDescription": "ขอขอบคุณสำหรับการเชื่อมต่อบัญชี {sns} ของคุณ โปรดระบุข้อมูลที่เหลือเพื่อลงทะเบียนบัญชีให้เสร็จสิ้น"
  },
  "voucher": {
    "cancel": "ยกเลิก",
    "confirm": "ยืนยัน",
    "number": "หมายเลขบัตรกำนัล",
    "purchseComplete": "การซื้อเสร็จสมบูรณ์",
    "selectStay": "โปรดเลือกกำหนดการเดินทางของคุณ",
    "stillVoucher": "นี่ยังคงเป็นบัตรกำนัล การเช็คอิน/เช็คเอาท์ยังไม่ได้เลือกเป็นการจองที่ได้รับการยืนยัน",
    "thankPurchase": "ขอขอบคุณสำหรับการซื้อของคุณ.",
    "update": "อัปเดต",
    "updateItinerary": "อัปเดตแผนการเดินทางของคุณ",
    "updateItineraryDesc": "โปรดเลือกวันที่เช็คอินและวันเช็คเอาต์ที่ต้องการเพื่ออัปเดตแผนการเดินทาง",
    "usingVoucher": "จองห้องพักโดยใช้บัตรกำนัลนี้",
    "valid": "ขณะนี้ Voucher สามารถใช้ได้แล้ว",
    "voucherDesc": "คุณสามารถจองห้องพักโดยใช้บัตรกำนัลนี้โดยเลือกวันที่เช็คอิน/เช็คเอาท์ที่นี่",
    "voucherDetails": "รายละเอียดบัตรกำนัล",
    "voucherNumber": "หมายเลขบัตรกำนัล"
  },
  "triplaPay": {
    "notFoundMsg": "ไม่พบบันทึกการชำระเงิน ธุรกรรมของคุณจะไม่สมบูรณ์",
    "isPaid": "การชำระเงินนี้เสร็จสมบูรณ์แล้ว",
    "isAlreadyPaid": "จ่ายแล้ว",
    "isFailed": "การชำระเงินล้มเหลว",
    "isCompleted": "การชำระเงินเสร็จสมบูรณ์",
    "isRefunded": "การชำระเงินของคุณได้รับการคืนเงินแล้ว",
    "refundedAt": "วันที่คืนเงิน",
    "paidAt": "วันจ่าย",
    "isCancelled": "การชำระเงินถูกยกเลิก",
    "total": "จำนวนเงินที่ชำระ",
    "pleaseReload": "งานนี้ยังไม่เสร็จภายในหนึ่งชั่วโมง กรุณาโหลดซ้ำ",
    "reload": "โหลดซ้ำ",
    "subject": "เรื่องการชำระเงิน",
    "amountIsChanged": "จำนวนเงินที่ชำระมีการเปลี่ยนแปลง โปรดคลิกปุ่ม \"อัปเดต\" เพื่อรีเฟรช",
    "terms": "โดยการยอมรับ tripla {0}, {1} และ {2} คุณเข้าใจว่าระบบการชำระเงินนี้ให้บริการโดย tripla Co., Ltd. และข้อมูลส่วนบุคคลของคุณจะถูกแบ่งปันกับ tripla Co., Ltd.",
    "paypalTerms": "PayPal ใช้สำหรับการชำระเงินด้วยบัตรเครดิต และจะระบุไว้ในใบแจ้งยอดบัตรของคุณ",
    "temsOfUse": "ข้อกำหนดการใช้งาน",
    "privacyPolicy": "นโยบายความเป็นส่วนตัว",
    "gdprPolicy": "นโยบาย GDPR",
    "pleaseScan": "กรุณาสแกนรหัส QR"
  },
  "triplaPayIssues": {
    "INVALID_PARAMETER_SYNTAX": "กรุณาตรวจสอบข้อมูลที่คุณกรอก.",
    "INVALID_EXPIRATION_YEAR": "โปรดตรวจสอบให้แน่ใจว่าวันหมดอายุของคุณถูกต้อง",
    "Invalid_card_number": "กรุณายืนยันหมายเลขบัตร",
    "Others": "กรุณาตรวจสอบข้อมูลที่คุณกรอก."
  },
  "lottery": {
    "registerForLottery": "ลงทะเบียนลอตเตอรี่",
    "selectedDatesError": "ระยะเวลาการเข้าพักที่เลือกควรอยู่ภายในระยะเวลาการเข้าพักสูงสุดและต่ำสุดที่อนุญาตสำหรับกิจกรรมลอตเตอรีนี้ ( {0} ~ {1} คืน)"
  },
  "recommenderWidget": {
    "previewText": "นี่คือโหมดแสดงตัวอย่าง ใช้ได้เพียง 5 นาที กรุณาคลิก 'ดูตัวอย่าง' อีกครั้งหลังจากผ่านไป 5 นาที อย่าลืมบันทึกการตั้งค่าของคุณสำหรับเวอร์ชันถ่ายทอดสดจริง"
  }
}