import PATH from 'src/routes/paths'

export const cookieExpires = 30 // days
export const dateFormatFromServer = 'YYYY-MM-DD'
export const DISALLOWED_QUERIES = ['email']
export const CROSS_DOMAIN_TRACKING_KEY = '_gl'
export const triplaCorpLinks = {
  termsOfUse: {
    zh_Hant: 'https://tw.tripla.io/membership-agreement/',
    en: 'https://en.tripla.io/membership-agreement/',
    ja: 'https://tripla.io/membership-agreement/'
  },
  standardTravelAgency: {
    zh_Hant: 'https://tw.tripla.io/standard-travel-agency/',
    zh_Hans: 'https://tw.tripla.io/standard-travel-agency/',
    ko: 'https://ko.tripla.io/standard-travel-agency/',
    en: 'https://en.tripla.io/standard-travel-agency/',
    ja: 'https://tripla.io/standard-travel-agency/'
  },
  privacyPolicy: {
    en: 'https://en.tripla.io/privacy-policy-2/',
    ja: 'https://tripla.io/privacy-policy/'
  },
  GDPR: {
    en: 'https://en.tripla.io/gdpr-privacy-policy/',
    ja: 'https://tripla.io/gdpr-privacy-policy/'
  },
  triplaPayTOS: {
    en: 'https://docs.tripla.jp/Terms-of-Use/tripla-pay-for-users',
    ja: 'https://docs.tripla.jp/Terms-of-Use/tripla-pay-for-users'
  },
  companyProfile: {
    en: 'https://en.tripla.io/about-us/',
    ja: 'https://tripla.io/about-us/'
  },
  bookingSupport: {
    ja: 'https://faq.tripla.ai/?facility_code=244d7930-c899-4f94-abd1-ff3397b3ed7a&locale=ja',
    en: 'https://faq.tripla.ai/?facility_code=244d7930-c899-4f94-abd1-ff3397b3ed7a&locale=en',
    ko: 'https://faq.tripla.ai/?facility_code=244d7930-c899-4f94-abd1-ff3397b3ed7a&locale=ko',
    zh_Hant:
      'https://faq.tripla.ai/?facility_code=244d7930-c899-4f94-abd1-ff3397b3ed7a&locale=zh_Hant',
    zh_Hans:
      'https://faq.tripla.ai/?facility_code=244d7930-c899-4f94-abd1-ff3397b3ed7a&locale=zh_Hans'
  }
}

export const JapanPrefectureMap = {
  Hokkaido: '北海道',
  Aomori: '青森県',
  Iwate: '岩手県',
  Miyagi: '宮城県',
  Akita: '秋田県',
  Yamagata: '山形県',
  Fukushima: '福島県',
  Ibaraki: '茨城県',
  Tochigi: '栃木県',
  Gunma: '群馬県',
  Saitama: '埼玉県',
  Chiba: '千葉県',
  Tokyo: '東京都',
  Kanagawa: '神奈川県',
  Niigata: '新潟県',
  Toyama: '富山県',
  Ishikawa: '石川県',
  Fukui: '福井県',
  Yamanashi: '山梨県',
  Nagano: '長野県',
  Gifu: '岐阜県',
  Shizuoka: '静岡県',
  Aichi: '愛知県',
  Mie: '三重県',
  Shiga: '滋賀県',
  Kyoto: '京都府',
  Osaka: '大阪府',
  Hyōgo: '兵庫県',
  Nara: '奈良県',
  Wakayama: '和歌山県',
  Tottori: '鳥取県',
  Shimane: '島根県',
  Okayama: '岡山県',
  Hiroshima: '広島県',
  Yamaguchi: '山口県',
  Tokushima: '徳島県',
  Kagawa: '香川県',
  Ehime: '愛媛県',
  Kōchi: '高知県',
  Fukuoka: '福岡県',
  Saga: '佐賀県',
  Nagasaki: '長崎県',
  Kumamoto: '熊本県',
  Ōita: '大分県',
  Miyazaki: '宮崎県',
  Kagoshima: '鹿児島県',
  Okinawa: '沖縄県'
}

export function getPrefectureList(locale, wildcard) {
  const options = Object.entries(JapanPrefectureMap).map(([value, jaText]) => ({
    value,
    text: locale === 'ja' ? jaText : value,
    jaText
  }))

  if (wildcard) {
    return attachOptionForWildcard(options, wildcard)
  }

  return options
}

export function getCurrentPrefectureValue(locale, text) {
  return getPrefectureList(locale).find((p) => {
    return p.value === text || p.jaText === text
  })?.value
}

export function getCurrentPrefectureText(locale, text) {
  return getPrefectureList(locale).find((p) => {
    return p.value === text || p.jaText === text
  })?.text
}

function attachOptionForWildcard(options = [], wildcard = '') {
  const suitableOption = options.find((p) => p.value === wildcard || p.jaText === wildcard)
  if (suitableOption) {
    suitableOption.value = wildcard
    return options
  } else {
    return [...options, { value: wildcard, text: wildcard, jaText: wildcard }]
  }
}

export const addressRequirementStatus = {
  DoNotAsk: 'do_not_ask',
  Optional: 'optional',
  Required: 'required'
}

export const paymentMethod = {
  OnSite: 'on_site',
  CreditCard: 'credit_card',
  OnSiteCreditCardRequired: 'on_site_credit_card_required',
  deferredPayment: 'deferred_payment',
  CreditCardInternational: 'credit_card_international' // for Korean facility, it's still credit_card when passing it to BE
}

export const FilterTypes = {
  Filter: 'Filter',
  HotelPlanCode: 'HotelPlanCode',
  RoomTypeCode: 'RoomTypeCode',
  VoucherCouponCode: 'VoucherCouponCode'
}

export const PriceType = {
  BRR: 'brr',
  Member: 'member',
  All: 'all',
  Secret: 'secret',
  Rounding: 'rounding'
}

export const ThirdPartyPayment = {
  GMO: 'gmo',
  GMOPayPal: 'gmo-paypal',
  Paygent: 'paygent',
  PaygentPayPal: 'paygent-paypal',
  TapPay: 'tappay',
  PayPal: 'paypal',
  PaymentHub: 'payment_hub',
  AdyenGMO: 'adyen_gmo',
  AdyenGMOPayPal: 'adyen_gmo_paypal'
}

export const PaymentGateway = {
  Adyen: 'adyen',
  PaymentHub: 'payment_hub',
  GMO: 'gmo',
  PayPal: 'paypal',
  Paygent: 'paygent',
  TapPay: 'tappay'
}

export const PaymentHubConfigs = {
  none: '',
  default: 'default',
  custom: 'custom',
  surehigh: 'surehigh'
}

export const kidsModeList = ['tiers', 'tiers_with_meal_and_bed_as_extras', 'ages']
export const PAYPAL_LIMIT_PRICE = 1000000
window.triplaSettings = {
  calendarCache: true
}
export const WBF_CLIENT_ID = 999
export const isOta = APP_ENV === 'ota'

export const triplaSocialMediaLinks = {
  instagram: {
    link: 'https://www.instagram.com/tripla_zine/'
  },
  facebook: {
    link: 'https://www.facebook.com/tripla.K.K'
  },
  linkedin: {
    link: 'https://www.linkedin.com/company/tripla.jp'
  },
  twitter: {
    link: 'https://twitter.com/tripla55269321'
  }
}

export const wbfMaxReservedLimit = 5

export const PaymentHubIntegrationType = {
  Direct: 'direct',
  Hosted: 'hosted'
}

export const NotBwRoutes = [
  PATH.iframeToken,
  PATH.iframeLiffToken,
  PATH.recommenderWidget,
  PATH.iframeChangeLocale,
  PATH.errorPages.serviceUnavailable,
  PATH.triplaPay,
  PATH.triplaPayDisplay,
  PATH.unsubscribeEmailConfirmation,
  PATH.unsubscribeEmailSuccess
]
export const allLocales = ['en', 'ja', 'ko', 'zh_Hans', 'zh_Hant', 'id', 'th', 'ar']

export const isTriplaOpenInLine = (function () {
  const searchParams = new URLSearchParams(location.search)
  return (
    (searchParams.get('triplabot_hide') === 'true' &&
      searchParams.get('utm_source') === 'triplabot' &&
      searchParams.get('utm_medium') === 'line') ||
    searchParams.get('open_in_line') === 'true'
  )
})()
